import React from "react";
import "./Ecommerce.css";
import { Outlet } from "react-router-dom";
import { BiStore } from "react-icons/bi";
import { FaShopify } from "react-icons/fa6";
import { GiConfirmed } from "react-icons/gi";
import { IoLogoStackoverflow } from "react-icons/io5";
import { MdOutlineMessage, MdProductionQuantityLimits } from "react-icons/md";

function ECommerce() {
  return (
    <>
      <div className="flex">
        <SidebarOfEcom />
        <div className="w-[80%] h-screen overflow-auto">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default ECommerce;

function SidebarOfEcom() {
  return (
    <div className="w-[20%] h-screen shadow bg-white">
      <div className="py-5 text-center px-6 text-xl font-medium">Ecommerce</div>
      <div className="mt-4">
        <div className="mx-auto w-56 btn btn-sm bg-[#EBF5F3] border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex justify-start items-center">
          <div className="text-xl">
            <BiStore />
          </div>
          <div className="font-medium ms-3 ">Store Setup</div>
        </div>

        <div className="mx-auto w-56 mt-3 btn btn-sm bg-[#EBF5F3] border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex justify-start items-center">
          <div className="text-xl">
            <MdOutlineMessage />
          </div>
          <div className="font-medium ms-3 ">Setup Messages</div>
        </div>

        <div className="mx-auto w-56 mt-3 btn btn-sm bg-[#EBF5F3] border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex justify-start items-center">
          <div className="text-xl">
            <FaShopify />
          </div>
          <div className="font-medium ms-3 ">Shopify Checkouts</div>
        </div>

        <div className="mx-auto w-56 mt-3 btn btn-sm bg-[#EBF5F3] border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex justify-start items-center">
          <div className="text-xl">
            <MdProductionQuantityLimits />
          </div>
          <div className="font-medium ms-3 ">Abandoned Cart Flow</div>
        </div>

        <div className="mx-auto w-56 mt-3 btn btn-sm bg-[#EBF5F3] border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex justify-start items-center">
          <div className="text-xl">
            <GiConfirmed />
          </div>
          <div className="font-medium ms-3 ">Confirmation Flow</div>
        </div>

        <div className="mx-auto w-56 mt-3 btn btn-sm bg-[#EBF5F3] border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex  justify-start items-center">
          <div className="text-xl">
            <IoLogoStackoverflow />
          </div>
          <div className="font-medium ms-3 ">Order Flow</div>
        </div>
      </div>
    </div>
  );
}
