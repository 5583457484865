import React, { useState, useEffect } from "react";
import { Button, styled } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import InputCard from "../../components/profile/InputCard";
import { useProfile } from "../../hooks/useProfile";
import { useNavigate } from "react-router-dom";

function Profile() {
  const { loading, profile, upProfile } = useProfile();

  const navigate = useNavigate();

  const [selectOption, setSelectedOption] = useState("");
  const [isClick, setClick] = useState(false);
  const [formData, setFormData] = useState({
    profilePicture: null,
    about: "",
    description: "",
    email: "",
    address: "",
    website1: "",
    website2: "",
    vertical: "",
  });
  const [previewUrl, setPreviewUrl] = useState("");

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // useEffect(() => {
  //   return () => {
  //     if (previewUrl) {
  //       URL.revokeObjectURL(previewUrl);
  //     }
  //   };
  // }, [previewUrl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, profilePicture: file });
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };

  const handleInputWebsite = () => {
    setClick(true);
  };

  const handleDeleteInputField = () => {
    setClick(false);
  };

  const handleSelectedOption = (event) => {
    setSelectedOption(event.target.value);
    setFormData({ ...formData, vertical: event.target.value });
  };

  const handleRemoveImage = () => {
    setFormData({ ...formData, profilePicture: null });
    setPreviewUrl("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      await upProfile(data);
      navigate("/");
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const options = [
    { label: "other", value: "other" },
    { label: "Auto", value: "Auto" },
    { label: "Beauty", value: "Beauty" },
    { label: "Apparel", value: "Apparel" },
    { label: "EDU", value: "EDU" },
    { label: "Entertain", value: "Entertain" },
    { label: "Event_Plan", value: "Event_Plan" },
    { label: "Finance", value: "Finance" },
    { label: "Grocery", value: "Grocery" },
    { label: "Govt", value: "Govt" },
    { label: "Hotel", value: "Hotel" },
    { label: "Health", value: "Health" },
    { label: "NonProfit", value: "NonProfit" },
    { label: "Prof_Services", value: "Prof_Services" },
    { label: "Retail", value: "Retail" },
    { label: "Travel", value: "Travel" },
    { label: "Restaurant", value: "Restaurant" },
  ];

  const HandleinputCard1 = {
    heading: "Profile Picture",
    Other: {
      tag1: "Max size of 5MB allowed.",
      tag2: " Image size of 640x640 is recommended.",
      tag3: "Images with a height or width of less than 192px may cause issues.",
      tag4: "",
    },
  };
  const HandleinputCard2 = {
    heading: "Description",
    Other: {
      tag1: "Description of the business",
      tag2: " Maximum of 256 characters",
      tag3: "",
      tag4: "",
    },
  };
  const HandleinputCard3 = {
    heading: "Address",
    Other: {
      tag1: " Address of the business",
      tag2: "maximum of 256 characters",
      tag3: "",
      tag4: "",
    },
  };
  const HandleinputCard4 = {
    heading: "Email",
    Other: {
      tag1: "Email address (in valid email format) to contact the business",
      tag2: "Maximum of 128 characters.",
      tag3: "",
      tag4: "",
    },
  };
  const HandleinputCard5 = {
    heading: "Vertical",
    Other: {
      tag1: "Industry of the business",
      tag2: "",
      tag3: "",
      tag4: "",
    },
  };
  const HandleinputCard6 = {
    heading: "Website",
    Other: {
      tag1: " URL includes(http:// or https://) associate with the business",
      tag2: "eg:facebook page, instagram page",
      tag3: "maximum of two websites",
      tag4: "",
    },
  };
  const HandleinputCard7 = {
    heading: "About",
    Other: {
      tag1: "About the business",
      tag2: "",
      tag3: "",
      tag4: "",
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="p-10">
        <div className="flex gap-10">
          <div className="w-1/2">
            <InputCard data={HandleinputCard1} />
          </div>
          <div className="w-1/2 flex justify-center gap-10 items-center">
            <div className="w-32 rounded-full overflow-hidden">
              <img
                src={
                  previewUrl ||
                  "https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg?t=st=1715769381~exp=1715772981~hmac=b8750f325a5d528796b515d4d906f7685e3065bcf154357504be26296e5e4028&w=740"
                }
                alt="Profile Preview"
              />
            </div>
            <div className="flex flex-col gap-3 ml-0 mt-4">
              <Button
                variant="outlined"
                component="label"
                role={undefined}
                tabIndex={-1}
              >
                Change
                <VisuallyHiddenInput
                  type="file"
                  name="profilePicture"
                  onChange={handleImageChange}
                />
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#0A474C" }}
                disableElevation
                onClick={handleRemoveImage}
                aria-label="Remove Profile Picture"
              >
                Remove
              </Button>
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-10 mt-5 w-full">
          <div className="w-1/2">
            <InputCard data={HandleinputCard7} />
            <input
              type="text"
              placeholder="About"
              className="input w-full bg-[#F0F0F0]"
              name="about"
              value={formData.about}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-1/2">
            <InputCard data={HandleinputCard2} />
            <input
              type="text"
              placeholder="Description"
              className="input w-full bg-[#F0F0F0]"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="flex justify-between gap-10 mt-5 w-full">
          <div className="w-1/2">
            <InputCard data={HandleinputCard4} />
            <input
              type="text"
              placeholder="Email"
              className="input w-full bg-[#F0F0F0]"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-1/2">
            <InputCard data={HandleinputCard3} />
            <input
              type="text"
              placeholder="Address"
              className="input w-full bg-[#F0F0F0]"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="flex justify-between gap-10 w-full mt-5">
          <div className="w-1/2">
            <InputCard data={HandleinputCard6} />
            <div className="items-center">
              <div className="flex items-center mt-4">
                <input
                  type="text"
                  placeholder="Enter Website"
                  className="input w-full bg-[#F0F0F0]"
                  name="website1"
                  value={formData.website1}
                  onChange={handleInputChange}
                />
                {!isClick ? (
                  <IoMdAdd
                    className="items-end ms-2 text-2xl text-gray-500 hover:cursor-pointer"
                    onClick={handleInputWebsite}
                  />
                ) : (
                  <>
                    <MdDelete
                      className="items-end ms-2 text-2xl text-gray-500 hover:cursor-pointer"
                      onClick={handleDeleteInputField}
                    />
                  </>
                )}
              </div>
              {isClick && (
                <div className="flex w-full justify-between items-center mt-4">
                  <input
                    type="text"
                    placeholder="Enter Website 2"
                    className="input w-full bg-[#F0F0F0]"
                    name="website2"
                    value={formData.website2}
                    onChange={handleInputChange}
                  />
                  <MdDelete
                    className="items-end ms-2 text-2xl text-gray-500 hover:cursor-pointer"
                    onClick={handleDeleteInputField}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="w-1/2">
            <InputCard data={HandleinputCard5} />
            <select
              onChange={handleSelectedOption}
              className="select select-bordered border-none bg-[#F0F0F0] input w-full"
              name="vertical"
              value={formData.vertical}
            >
              {options.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-7 flex justify-end gap-3 p-4 mr-6">
          <Button variant="outlined" disableElevation>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            sx={{ backgroundColor: "#0A474C" }}
            type="submit"
          >
            {loading ? <span className="loading loading-dots"></span> : "Save"}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default Profile;
