import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useMetaUserDetails from "../zustand/useMetaUserDetails";

const useProfile = () => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState([]);
  const { setMetaUserDetails } = useMetaUserDetails();

  const upProfile = async (data) => {
    try {
      setLoading(true);

      const res = await axios.post("/whatsapp/details/whatsappProfile", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(res.data);
      setMetaUserDetails(res.data);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, profile, upProfile };
};

const useGetProfile = () => {
  const [loading, setloading] = useState(false);
  const { metaUserDetails, setMetaUserDetails } = useMetaUserDetails();
  const [refreshData, setRefreshData] = useState(false);

  const getMetaUserDetails = async () => {
    try {
      setloading(true);

      const res = await axios.get("/whatsapp/details/whatsappProfile/get");

      console.log("data", res.data);
      setMetaUserDetails(res.data);
    } catch (error) {
      // toast.error(error.response?.data?.error || error.message);
      console.log(error.response?.data?.error || error.message);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getMetaUserDetails();
  }, [refreshData]);

  return { refreshData, setRefreshData };
};

export { useProfile, useGetProfile };
