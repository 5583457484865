import React, { useState, useEffect, useCallback, useMemo } from "react";
import GoBackHeader from "../eCommerce/GoBackHeader";
import { Autocomplete, TextField } from "@mui/material";
import { IoDocumentText, IoPlayCircle } from "react-icons/io5";
import { MdImage } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import TamplateFormat from "./innerpage/TamplateFormat";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { CgMailReply } from "react-icons/cg";
import { FaArrowLeft } from "react-icons/fa";
import { BiLinkExternal } from "react-icons/bi";
import img2 from "../../photos/whatsapp.png"
import img1 from "../../photos/ioswhatsapp.png"
import { BiVideo } from "react-icons/bi";
import { IoEllipsisVertical } from "react-icons/io5";
import { CgSmileMouthOpen } from "react-icons/cg";
import { BsPaperclip } from "react-icons/bs";
// import "../../pages/contacts/customStyles.css";  
import "../../pages/template/Template.css";
import { IoCloseSharp } from "react-icons/io5";
import { useCreateTemplate } from "../../hooks/useManagetemplate.js";
import toast from "react-hot-toast";
import { MdOutlineCameraAlt } from "react-icons/md";
import { MdOutlineCall } from "react-icons/md";
import CarousalCard from "./CarousalCard.jsx";
import { IoMdMic } from "react-icons/io";

import { LuForward } from "react-icons/lu";



function CreateTemplate() {
  const { loadTemplate, istemplateresponse, templateCreate } =
    useCreateTemplate();

  const [childState, setChildState] = useState({
    input: [],
    isUrl: [],
    isPhoneNumber: [],
  });

  const ChildData = useCallback((data) => {
    setChildState(data);
  }, []);

  console.log("data from child are:", childState.input);
  console.log("data from child are:", childState.isUrl);
  console.log("data from child are:", childState.isPhoneNumber);
  console.log("data for buttons :", childState.isPhoneNumber[0]?.[0]);
  // const [isbutton ,setbutton] = useState("");
  // const mapnumber = ()=>{
  //   {childState.isPhoneNumber[0][0].map((button)=>{
  //     setbutton(button)
  //   })}
  // }
  // mapnumber();
  // console.log("buttons name is ",isbutton);

  const data = {
    heading: "New Template Message",
    link: "/template",
  };

  const TemplateCategoryData = [
    { label: "UTILITY", id: 1 },
    { label: "MARKETING", id: 2 },
    { label: "AUTHENTICATION", id: 3 },
  ];

  const TemplateLanguageData = [
    { label: "Afrikaans", id: 1, code: "af" },
    { label: "Albanian", id: 2, code: "sq" },
    { label: "Arabic", id: 3, code: "ar" },
    { label: "Azerbaijani", id: 4, code: "az" },
    { label: "Bengali", id: 5, code: "bn" },
    { label: "Bulgarian", id: 6, code: "bg" },
    { label: "Catalan", id: 7, code: "ca" },
    { label: "Chinese (CHN)", id: 8, code: "zh_CN" },
    { label: "Chinese (HKG)", id: 9, code: "zh_HK" },
    { label: "Chinese (TAI)", id: 10, code: "zh_TW" },
    { label: "Croatian", id: 11, code: "hr" },
    { label: "Czech", id: 12, code: "cs" },
    { label: "Danish", id: 13, code: "da" },
    { label: "Dutch", id: 14, code: "nl" },
    { label: "English", id: 15, code: "en" },
    { label: "English (UK)", id: 16, code: "en_GB" },
    { label: "English (US)", id: 17, code: "en_US" },
    { label: "Estonian", id: 18, code: "et" },
    { label: "Filipino", id: 19, code: "fil" },
    { label: "Finnish", id: 20, code: "fi" },
    { label: "French", id: 21, code: "fr" },
    { label: "Georgian", id: 22, code: "ka" },
    { label: "German", id: 23, code: "de" },
    { label: "Greek", id: 24, code: "el" },
    { label: "Gujarati", id: 25, code: "gu" },
    { label: "Hebrew", id: 26, code: "he" },
    { label: "Hindi", id: 27, code: "hi" },
    { label: "Hungarian", id: 28, code: "hu" },
    { label: "Icelandic", id: 29, code: "is" },
    { label: "Indonesian", id: 30, code: "id" },
    { label: "Italian", id: 31, code: "it" },
    { label: "Japanese", id: 32, code: "ja" },
    { label: "Kannada", id: 33, code: "kn" },
    { label: "Kazakh", id: 34, code: "kk" },
    { label: "Korean", id: 35, code: "ko" },
    { label: "Kyrgyz", id: 36, code: "ky" },
    { label: "Lao", id: 37, code: "lo" },
    { label: "Latvian", id: 38, code: "lv" },
    { label: "Lithuanian", id: 39, code: "lt" },
    { label: "Macedonian", id: 40, code: "mk" },
    { label: "Malay", id: 41, code: "ms" },
    { label: "Malayalam", id: 42, code: "ml" },
    { label: "Marathi", id: 43, code: "mr" },
    { label: "Norwegian", id: 44, code: "no" },
    { label: "Persian", id: 45, code: "fa" },
    { label: "Polish", id: 46, code: "pl" },
    { label: "Portuguese (BR)", id: 47, code: "pt_BR" },
    { label: "Portuguese (POR)", id: 48, code: "pt_PT" },
    { label: "Punjabi", id: 49, code: "pa" },
    { label: "Romanian", id: 50, code: "ro" },
    { label: "Russian", id: 51, code: "ru" },
    { label: "Kinyarwanda", id: 52, code: "rw" },
    { label: "Serbian", id: 53, code: "sr" },
    { label: "Slovak", id: 54, code: "sk" },
    { label: "Slovenian", id: 55, code: "sl" },
    { label: "Spanish", id: 56, code: "es" },
    { label: "Spanish (ARG)", id: 57, code: "es_AR" },
    { label: "Spanish (SPA)", id: 58, code: "es_ES" },
    { label: "Spanish (MEX)", id: 59, code: "es_MX" },
    { label: "Swahili", id: 60, code: "sw" },
    { label: "Swedish", id: 61, code: "sv" },
    { label: "Tamil", id: 62, code: "ta" },
    { label: "Telugu", id: 63, code: "te" },
    { label: "Thai", id: 64, code: "th" },
    { label: "Turkish", id: 65, code: "tr" },
    { label: "Ukrainian", id: 66, code: "uk" },
    { label: "Urdu", id: 67, code: "ur" },
    { label: "Uzbek", id: 68, code: "uz" },
    { label: "Vietnamese", id: 69, code: "vi" },
    { label: "Zulu", id: 70, code: "zu" },
  ];

  const TheadingData = [
    // { label: "None", id: 1 },
    { label: "Text", id: 2 },
    { label: "Image", id: 3 },
    { label: "Video", id: 4 },
    { label: "Document", id: 5 },
    { label: "Location", id: 6 },
    // { label: "CAROUSEL", id: 7 },
    // { label: "limited_time_offer", id: 8 }
  ];
  const [isTemplateName, SetTemplateName] = useState("");
  console.log("templatename is :", isTemplateName);
  const [selectedType, setSelectedType] = useState(null);
  console.log("template type is :", selectedType);

  const [headerText, setHeaderText] = useState("");
  // console.log("header is :", headerText);

  // it is for template format body text
  const [inputValue, setInputValue] = useState("");
  const [objectsArray, setObjectsArray] = useState([]);


  // it is for caursol bubble message 
  const [inputBubble, setbubble] = useState("");
  const handleParameterValChange = (event, id) => {
    const newValue = event.target.value;
    setObjectsArray(
      // New Method
      objectsArray.map((item) =>
        item.id === id ? { ...item, parameterVal: newValue } : item
      )

      // old method
      // objectsArray.map((item) => {
      //   if (item.id === id) {
      //     return { ...item, parameterVal: newValue };
      //   } else {
      //     return item;
      //   }
      // })
    );
  };

  console.log("body text is :");
  const transformedBodyText = objectsArray.map((item) => item.parameterVal);
  const Newbody_text = [transformedBodyText];
  console.log("full body text:", inputValue);
  console.log("body text is ");
  const handleTypeChange = (event, newValue) => {
    setSelectedType(newValue.label);
    setHeaderText("");
  };





  const CarusolTemplateFormat = [{ label: "IMAGE", id: 1 },
  { label: "VIDEO", id: 2 }
  ]




  const [cardformat, setcardformat] = useState("")
  const habdlechangeCarusolFormat = (event, newValue) => {
    setcardformat(newValue.label)
  }


  const formatText = (text) => {
    return text
      .replace(/\*(.*?)\*/g, "<b>$1</b>") // Bold
      .replace(/_(.*?)_/g, "<i>$1</i>") // Italic
      .replace(/~(.*?)~/g, "<s>$1</s>"); // Strikethrough
  };
  const [isCategory, setCategory] = useState("");
  console.log("template category", isCategory);
  const handleCatorgyChange = (event, newValue) => {
    setCategory(newValue.label);
  };

  const [isLanguage, setLanuage] = useState("");
  console.log("lanuage is :", isLanguage);
  const HandleLanuage = (event, newValue) => {
    setLanuage(newValue.code);
  };

  const [footer, setfooter] = useState("");

  console.log("footer is :", footer);


  const uploadTemplate = async () => {
    const Phone_number = childState.isPhoneNumber;
    // Phone number validation
    // const phoneNumberRegex = /^\+?[0-9]{7,}$/; // Basic phone number regex
    // const phoneNumberDetailedRegex = /^\+[0-9]{2,}$/; // Ensure at least 2 digits follow the +

    // // Phone number validation logic
    // if (Phone_number) {
    //   if (
    //     !Array.isArray(Phone_number) ||                    // Ensure Phone_number is an array
    //     Phone_number.length !== 2                       // Ensure it has exactly two elements
    //     // Phone_number.some(item => !phoneNumberRegex.test(item.trim())) || // Validate basic phone number pattern
    //     // Phone_number.some(item => item.trim().startsWith('+') && !phoneNumberDetailedRegex.test(item.trim())) // Validate '+' prefixed numbers
    //   ) {
    //     toast.error("Both fields are required and must be valid phone numbers");
    //     return;
    //   }
    // }

    // Gather all required data
    const templateData = {
      templateName: isTemplateName.toLowerCase(),
      languageCode: isLanguage, // Assuming this is the correct variable name
      category: isCategory,
      format: selectedType,
      headerText: headerText,
      templateBody: inputValue,
      templatefooter: footer,
      body_text: Newbody_text,
      UrlButtons: childState?.isUrl || [],
      Phone_Number: Phone_number,
      QUICKREPLYtext: childState?.input || [],
    };

    // Validate required fields
    const requiredFields = [
      "templateName",
      "languageCode",
      "category",
      "format",
      "templateBody",
    ];
    for (const field of requiredFields) {
      if (!templateData[field]) {
        toast.error(`${field} is required`);
        return;
      }
    }

    try {
      await templateCreate(templateData);


      console.log("|is name looks likes this ", isTemplateName);
    } catch (error) {
      console.error("Error uploading template:", error);
      toast.error("Failed to upload template. Please try again.");
    }
  };

  return (
    <>
      {loadTemplate && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
        </div>
      )}
      <GoBackHeader data={data} />
      <div className="py-7 px-6 ">
        <div className="shadow rounded-md bg-white px-8 py-16">
             {/* first container */}
          <div className="container">
            <div className=" flex flex-col md:flex-row md:gap-12">
              <div className="w-full mb-6 md:mb-0 ">
                <TextComponent
                  heading="Template Category"
                  body="Your template should fall under one of these categories."
                />

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={TemplateCategoryData}
                  getOptionLabel={(option) => option.label}
                  sx={{
                    width: "100%",
                    background: "#F0F0F0",
                    borderRadius: "8px",
                    marginTop: "25px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .css-z8b3ht-MuiFormLabel-root-MuiInputLabel-root": {
                      // display: "none",
                    },
                    "& .css-8ujita-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                    {
                      padding: "7.5px 4px 7.5px 13px",
                    },
                    "& .css-1b9mdof-MuiFormLabel-root-MuiInputLabel-root": {
                      color: "#A9A9A9",
                      fontSize: "medium",
                    },
                  }}
                  onChange={handleCatorgyChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select message category" />
                  )}
                />
              </div>

              <div className="w-full ">
                <TextComponent
                  heading="Template Language"
                  body="You will need to specify the language in which message template is submitted."
                />

                <Autocomplete
                  disablePortal
                  id="TemplateLanguage"
                  options={TemplateLanguageData}
                  sx={{
                    width: "100%",
                    marginTop: "25px",
                    background: "#F0F0F0",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .css-z8b3ht-MuiFormLabel-root-MuiInputLabel-root": {
                      // display: "none",
                    },
                    "& .css-8ujita-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                    {
                      padding: "7.5px 4px 7.5px 13px",
                    },
                    "& .css-1b9mdof-MuiFormLabel-root-MuiInputLabel-root": {
                      color: "#A9A9A9",
                      fontSize: "medium",
                    },
                  }}
                  onChange={HandleLanuage}
                  renderInput={(params) => (
                    <TextField {...params} label="Select message Language" />
                  )}
                />
              </div>
            </div>
          </div>

          {/* secound container */}
          <div className="container  mx-auto px-4">
            <div className="  flex flex-col     lg:flex-row justify-between gap-8 mt-10 ">
              <div className="w-full lg:w-1/2 ">
                <div>
                  <TextComponent
                    heading="Template Name"
                    body="Name can only be in lowercase alphanumeric characters and underscores. Special characters and white-space are not allowed"
                    body1="e.g. - app_verification_code"
                  />
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="input input-bordered w-full p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal pt-0 pb-0"
                    onChange={(e) => {
                      SetTemplateName(e.target.value);
                    }}
                  />
                </div>

                <div className="mt-10">
                  <TextComponent
                    heading="Template Type"
                    body="Your template type should fall under one of these categories."
                  />
                  <Autocomplete
                    disablePortal
                    id="TemplateLanguage"
                    options={TheadingData}
                    getOptionLabel={(option) => option.label}
                    sx={{
                      width: "100%",
                      marginTop: "25px",
                      background: "#F0F0F0",
                      borderRadius: "8px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .css-z8b3ht-MuiFormLabel-root-MuiInputLabel-root": {
                        // display: "none",
                      },
                      "& .css-8ujita-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                      {
                        padding: "7.5px 4px 7.5px 13px",
                      },
                      "& .css-1b9mdof-MuiFormLabel-root-MuiInputLabel-root": {
                        color: "#A9A9A9",
                        fontSize: "medium",
                      },
                    }}
                    onChange={handleTypeChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Select message type" />
                    )}
                  />
                </div>
                {selectedType === "CAROUSEL" ? (
                  <div>
                    {/* <div className="mt-10">
                      <TamplateFormat
                        inputValue={inputBubble}
                        setInputValue={setbubble}
                        objectsArray={objectsArray}
                        setObjectsArray={setObjectsArray}

                      />
                    </div>

                    {objectsArray.length > 0 && (
                      <div className="mt-5">
                        {objectsArray.map((item) => (
                          <div
                            className="flex justify-between items-center"
                            key={item.id}
                          >
                            <div className="text-lg font-normal mt-2">{`{{${item.id}}}`}</div>
                            <input
                              value={item.parameterVal}
                              onChange={(event) => {
                                handleParameterValChange(event, item.id);
                              }}
                              type="text"
                              placeholder="Enter parameter value"
                              className="input input-xs input-bordered w-5/6 h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                            />
                          </div>
                        ))}
                      </div>
                    )}


                    <div className="mt-10">
                      <TextComponent
                        heading="Template Type"
                        body="Your template type should fall under one of these categories."
                      />
                      <Autocomplete
                        disablePortal
                        id="CAROUSALFORMAT "
                        options={CarusolTemplateFormat}
                        getOptionLabel={(option) => option.label}
                        sx={{
                          width: "100%",
                          marginTop: "10px",
                          background: "#F0F0F0",
                          borderRadius: "8px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .css-z8b3ht-MuiFormLabel-root-MuiInputLabel-root": {
                            display: "none",
                          },
                          "& .css-8ujita-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                          {
                            padding: "7.5px 4px 7.5px 13px",
                          },
                          "& .css-1b9mdof-MuiFormLabel-root-MuiInputLabel-root": {
                            color: "#A9A9A9",
                            fontSize: "medium",
                          },
                        }}
                        onChange={habdlechangeCarusolFormat}
                        renderInput={(params) => (
                          <TextField {...params} label="Select message type" />
                        )}
                      />
                    </div> */}

                  </div>





                ) : (<div></div>)}




                {selectedType === "Text" && (
                  <div className="mt-10">
                    <TextComponent
                      heading="Template Header Text (Optional)"
                      body="Header text is optional and only upto 60 characters are allowed."
                    />
                    <input
                      type="text"
                      value={headerText}
                      onChange={(e) => {
                        setHeaderText(e.target.value);
                      }}
                      placeholder="Enter header text here"
                      className="input input-bordered w-full h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                    />
                  </div>
                )}

                <div className="mt-10">
                  <TamplateFormat
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    objectsArray={objectsArray}
                    setObjectsArray={setObjectsArray}
                  />
                </div>

                {objectsArray.length > 0 && (
                  <div className="mt-5">
                    {objectsArray.map((item) => (
                      <div
                        className="flex justify-between items-center"
                        key={item.id}
                      >
                        <div className="text-lg font-normal mt-2">{`{{${item.id}}}`}</div>
                        <input
                          value={item.parameterVal}
                          onChange={(event) => {
                            handleParameterValChange(event, item.id);
                          }}
                          type="text"
                          placeholder="Enter parameter value"
                          className="input input-xs input-bordered w-5/6 h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                        />
                      </div>
                    ))}
                  </div>
                )}

                <div className="mt-10">
                  <TextComponent
                    heading="Template Footer (Optional)"
                    body="Your message content. Upto 60 characters are allowed."
                  />
                  <input
                    type="text"
                    placeholder="Enter footer text here"
                    className="input input-bordered w-full h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                    onChange={(e) => {
                      setfooter(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>

              <div className=" w-full lg:w-1/2 sticky top-0 max-h-96   ">

                {selectedType === "CAROUSEL" ? (<div >
                  {/* <TextComponent
                    heading="Template Preview"
                    body="Your template message preview. It will update as you fill in the values in the form."
                  />

                  <div className="mt-10 w-8/12 mx-auto">
                    <CarousalCard childState={childState} setChildState={setChildState} />
                  </div> */}


                
                </div>) : (<div>
                  <TextComponent
                    heading="Template Preview"
                    body="Your template message preview. It will update as you fill in the values in the form."
                  />

                  <div className="container  flex justify-center">
                    <div className="mockup-phone sm:w-[285px]  w-[285px]  lg:w-[285px]  ">
                      <div className="camera "></div>
                      <div className="display bg-white min-h-[450px] relative ">
                        <div className="flex flex-col min-h-[500px]  bg-gray-100">

                          <div className="bg-teal-700 text-white p-2  min-h-[45px] flex items-center">
                          
                            <FaArrowLeft className="mr-2 text-sm text-white mt-4" />

                            <div className="w-8 h-8 bg-yellow-400 rounded-full flex items-center justify-center mr-2 mt-4">
                              <span className="text-black font-bold">T</span>
                            </div>
                            <div className="flex-grow">
                              <div className="font-semibold mt-3">TheBotmode</div>
                              <div className="text-xs  font-normal ">online</div>
                            </div>
                            <div className="flex items-center h-[45px] mt-3 ">
                              <BiVideo className="mx-2 text-lg text-white" />
                              <MdOutlineCall className="mx-1 text-lg text-white" />
                              <IoEllipsisVertical className="mx-1 text-lg text-white" />
                            </div>
                          </div>

                          {/* Chat area */}
                          <div className="flex-grow bg-gray-200 p-4 relative">
                            <img src="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png" className="h-full w-full object-cover absolute inset-0" alt="whatsapp background" />
                            <div className="  h-[20px] w-[45px]  bg-slate-100 rounded-sm border-1   absolute top-1 left-[40%]  flex items-center justify-center z-[3] bg-opacity-55"><span className="text-xs text-center  font-normal  text-[#54656F]">Today</span></div>
                            <div className="absolute rounded-t-md top-15 left-3 w-10/12 mx-auto max-h-[calc(100%-96px)] overflow-y-auto custom-scroll ">
                              <div className="bg-white shadow p-2 text-center overflow-hidden">
                                {selectedType !== "None" && selectedType !== "Text" && (
                                  <div className="w-auto h-40 bg-[#F8F8F8] rounded-md flex gap-5 justify-center items-center">
                                    {(selectedType === null || selectedType === "Image") && <MdImage className="text-4xl text-yellow-500" />}
                                    {(selectedType === null || selectedType === "Video") && <IoPlayCircle className="text-4xl text-blue-600" />}
                                    {(selectedType === null || selectedType === "Document") && <IoDocumentText className="text-4xl text-pink-600" />}
                                    {selectedType === "Location" && <FaLocationDot className="text-4xl text-red-600" />}
                                  </div>
                                )}
                                <div className="text-sm font-bold text-start mt-3">{headerText}</div>
                                <div className="font-medium mt-3 text-start overflow-hidden">
                                  <div className="overflow-hidden" dangerouslySetInnerHTML={{ __html: formatText(inputValue) }} />
                                </div>
                                <div className="text-xs font-normal text-start text-blue-600 mt-4">{footer}</div>
                              </div>

                              {childState.isPhoneNumber.map((phoneNumberArray, index) => (
                                <div key={index} className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                                  <MdOutlineCall className="text-blue-600 text-sm" />
                                  {phoneNumberArray[0]}
                                </div>
                              ))}

                              {childState.isUrl.map((urlArray, index) => (
                                <div key={index} className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                                  <BiLinkExternal className="text-blue-600 text-sm" />
                                  {urlArray[1]}
                                </div>
                              ))}

                              {childState.input.map((button, index) => (
                                <div key={index} className="templatebuttons rounded-b-md flex gap-1 items-center justify-center border-blue border-1">
                                  <CgMailReply className="text-blue-600 text-sm" />
                                  {button}
                                </div>
                              ))}
                            </div>
                            <div className="h-6 w-6 rounded-full bg-gray-500 bg-opacity-30 absolute top-[35%] ml-1 right-1.5 flex items-center justify-center">
                              <LuForward className="text-white text-sm" />
                            </div>
                          </div>

                          {/* Input area */}
                          <div className=" w-full p-2 flex items-center mb-2 absolute bottom-1 left-0">

                            <div className="flex-grow bg-white rounded-full w-[80%]  py-2 flex items-center">
                              <CgSmileMouthOpen className="text-gray-600 mx-2 text-xl " />
                              <input
                                type="text"
                                placeholder="Type a message"
                                className="w-[60%] outline-none"
                              />
                            
                              <BsPaperclip className="text-gray-600  mx-2 text-lg" />
                              <MdOutlineCameraAlt className="text-gray-600  mx-2 text-lg" />
                             
                            </div>
                            <div className="w-8 h-8 bg-teal-700 rounded-full flex items-center justify-center ml-1 ">
                          
                              <IoMdMic className="text-white text-lg " />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>)}

              </div>
            </div>
          </div>
          <div className="mt-60 lg:mt-30">
            <TextComponent
              heading="Interactive Actions"
              body="In addition to your message, you can send actions with your message."
              body1="Maximum 25 characters are allowed in CTA button title & Quick Replies."
            />

            <div className="mt-11">
              <DynamicInput fromParentData={ChildData} />
            </div>
          </div>
          <button
            className="btn btn-sm bg-green-500  mt-4 hover:bg-green-500 text-white"
            onClick={uploadTemplate}
          >
            submit template
          </button>
        </div>
      </div>
    </>
  );
}

export default CreateTemplate;

export function TextComponent({ heading, body, body1 }) {
  return (
    <>
      <div className="text-lg font-medium mt-5">{heading}</div>
      <div className="text-xs font-normal my-1 text-[#6E6E6E]">{body}</div>
      {body1 && (
        <div className="text-xs font-normal my-1 text-[#45c854d2]">{body1}</div>
      )}
    </>
  );
}

function DynamicInput({ fromParentData }) {
  const [input, setInput] = useState([{ inputfield: "" }]);
  const [isUrl, SetUrl] = useState([{ urlInput: "", urlInput2: "" }]);
  const [isPhoneNumber, setPhonenumber] = useState([
    { NumberInput1: "", Numberinput2: "" },
  ]);

  const Maxcount = 7;
  const MaxURl = 2;
  const MaxCountNumber = 1;

  console.log(input);
  console.log(isUrl);
  console.log(isPhoneNumber);
  console.log("url button", isUrl[0]);
  // console.log("data from usememo",transformedData)

  const addinput = () => {
    if (input.length < Maxcount) {
      setInput([...input, { inputfield: "" }]);
    } else {
      toast.error("Maximum quick replies reached");
    }
  };

  const RemoveInput = (index) => {
    const list = [...input];
    list.splice(index, 1);
    setInput(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...input];
    list[index][name] = value;
    setInput(list);
  };

  const addUrl = () => {
    if (isUrl.length < MaxURl) {
      SetUrl([...isUrl, { urlInput: "", urlInput2: "" }]);
    } else {
      toast.error("Maximum URLs reached");
    }
  };

  const RemoveUrl = (index) => {
    const UrlList = [...isUrl];
    UrlList.splice(index, 1);
    SetUrl(UrlList);
  };

  const handleURlChange = (e, index) => {
    const { name, value } = e.target;
    const UrlList = [...isUrl];
    UrlList[index][name] = value;
    SetUrl(UrlList);
  };

  const AddNumber = () => {
    if (isPhoneNumber.length < MaxCountNumber) {
      setPhonenumber([
        ...isPhoneNumber,
        { NumberInput1: "", Numberinput2: "" },
      ]);
    } else {
      toast.error("Only one phone number is allowed");
    }
  };

  const RemoveNumber = (index) => {
    const InputNumberList = [...isPhoneNumber];
    InputNumberList.splice(index, 1);
    setPhonenumber(InputNumberList);
  };

  const handlePhoneNumberChange = (e, index, newValue = null) => {
    if (newValue) {
      const InputNumberList = [...isPhoneNumber];
      InputNumberList[index].Numberinput2 = newValue;
      setPhonenumber(InputNumberList);
    } else if (e && e.target) {
      const { name, value } = e.target;
      const InputNumberList = [...isPhoneNumber];
      InputNumberList[index][name] = value;
      setPhonenumber(InputNumberList);
    }
  };

  const transformedData = useMemo(
    () => ({
      input: input.map((item) => item.inputfield),
      isUrl: isUrl.map((item) => [item.urlInput, item.urlInput2]),
      isPhoneNumber: isPhoneNumber.map((item) => [
        item.NumberInput1,
        item.Numberinput2,
      ]),
    }),
    [input, isUrl, isPhoneNumber]
  );

  
  useEffect(() => {
    fromParentData(transformedData);
  }, [transformedData, fromParentData]);

  return (
    <>
    <div className="container">
      {isPhoneNumber.map((SingleNumberInput, index) => (
        <div key={index}>
          <div className="w-full lg:flex gap-3 items-center mt-5  ">
            <span className="  w-full mt-2  lg:w-[25%] text-inherit text-sm">
              Call to Action 1 :
            </span>
            <input
              type="text"
              placeholder=""
              value="Phone Number"
              className="w-full mt-4 lg:w-[25%] input bg-[#F0F0F0] lg:max-w-xs text-sm"
              readOnly 
            />
            <input  
              type="text"
              placeholder="Button Title "
              className=" w-full mt-4 lg:w-[25%]  input bg-[#F0F0F0]  lg:max-w-xs text-sm"
              name="NumberInput1"
              id="inputnumber"
              value={SingleNumberInput.NumberInput1}
              required
              onChange={(e) => handlePhoneNumberChange(e, index)}
            />
            <div className="w-full  phone-input-container1  lg:max-w-xs text-xs">
              <PhoneInput
                getOptionLabel={(option) => option.label}
                international
                defaultCountry="IN"
                name="Numberinput2"
                id="inputnumber2"
                value={SingleNumberInput.Numberinput2}
                required
                onChange={(value) =>
                  handlePhoneNumberChange(null, index, value)
                }
              />
            </div>
            {isPhoneNumber.length > 0 && (
              <span
                className="      lg:ml-6 text-xl"
                onClick={() => RemoveNumber(index)}
              >
                <IoCloseSharp />
              </span>
            )}
          </div>
        </div>
      ))}

      {isUrl.map((UrlMessage, index) => (
        <div key={index}>   
          <div className=" w-full   lg:flex  items-center mt-5  gap-3 ">
            <span className="w-full  flex-col mt-4  lg:w-[15%] text-sm h-5 lg:mr-3">visit website :</span>
            <input   
              type="text"
              placeholder="url"
              value="url"  
              className=" w-full mt-4  lg:w-[25%]   input bg-[#F0F0F0] lg:max-w-xs text-sm lg:mr-3"
              readOnly
            />
            <input
              type="text"
              placeholder="Button Title"
              className=" w-full mt-4 lg:w-[25%] input bg-[#F0F0F0]  lg:max-w-xs text-sm lg:mr-3"
              name="urlInput"
              id="url1"
              value={UrlMessage.urlInput}
              required
              onChange={(e) => handleURlChange(e, index)}
            />
            <input
              type="text"
              placeholder="Button Value"
              className="w-full  mt-4 lg:w-[25%] input bg-[#F0F0F0]  lg:max-w-xs text-sm "
              name="urlInput2"  
              id="url2"
              value={UrlMessage.urlInput2}
              required
              onChange={(e) => handleURlChange(e, index)}
            />
            {isUrl.length > 0 && (
              <span className=" ml-6 text-xl " onClick={() => RemoveUrl(index)}>
                <IoCloseSharp />
              </span>
            )}
          </div>
        </div>
      ))}

      {input.map((Singleinputfield, index) => (
        <div key={index}>
          <div className="w-full  lg:flex gap-3 items-center mt-5 lg:mr-3 ">
            <span className="text-sm mt-4">Quick Reply </span>
            <input
              type="text"
              placeholder="quick reply"
              className="input mt-4 bg-[#F0F0F0] w-full lg:max-w-xs text-sm lg:mr-3"
              name="inputfield"
              id="inputfield"  
              value={Singleinputfield.inputfield}
              required
              onChange={(e) => handleInputChange(e, index)}
            />
            {input.length > 0 && (
              <span className="ml-6 text-xl" onClick={() => RemoveInput(index)}>
                <IoCloseSharp />
              </span>
            )}
          </div>
        </div>
      ))}

      <div className="flex gap-4">
        {isPhoneNumber.length < MaxCountNumber && (
          <div
            className="w-auto mt-6 flex gap-2 btn btn-sm btn-outline hover:bg-white hover:text-black"
            onClick={AddNumber}
          >
            <span> + Phone Number </span>
            <span className="h-6 w-6 rounded-full bg-[#E0E0E0] items-center justify-center flex">
              {MaxCountNumber - isPhoneNumber.length}
            </span>
          </div>
        )}

        {isUrl.length < MaxURl && (
          <div
            className="w-auto mt-6 flex gap-2 btn btn-sm btn-outline hover:bg-white hover:text-black"
            onClick={addUrl}
          >
            <span className="text-gray-400"> + URL </span>
            <span className="h-6 w-6 rounded-full bg-[#E0E0E0] items-center justify-center flex">
              {MaxURl - isUrl.length}
            </span>
          </div>
        )}

        {input.length < Maxcount && (
          <div
            className="w-auto mt-6 flex gap-2 btn btn-sm btn-outline hover:bg-white hover:text-black"
            onClick={addinput}
          >
            <span>+ quick reply</span>
            <span className="h-6 w-6 rounded-full bg-[#E0E0E0] items-center justify-center flex">
              {Maxcount - input.length}
            </span>
          </div>
        )}
      </div>
      </div>
    </>
  );
}
