// import React, { useEffect, useRef } from "react";
// import Message from "./Message";
// import useGetMessages from "../../hooks/useGetMessages";
// import MessageSkeleton from "../skeletons/MessageSkeleton";
// import useListenMessages from "../../hooks/useListenMessages";

// const Messages = () => {
//   const { loading, messages } = useGetMessages();
//   useListenMessages();

//   const lastMessageRef = useRef();
//   useEffect(() => {
//     setTimeout(() => {
//       lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
//     }, 100);
//   }, [messages]);

//   return (
//     <div className="px-4 flex-1 overflow-auto">
//       {!loading &&
//         messages.length > 0 &&
//         messages.map((message, idx) => (
//           <div key={idx} ref={lastMessageRef}>
//             <Message message={message} />
//           </div>
//         ))}
//       {loading && [...Array(3)].map((_, idx) => <MessageSkeleton key={idx} />)}
//       {!loading && messages.length === 0 && (
//         <p className="text-center">Send a message to start the conversation</p>
//       )}
//     </div>
//   );
// };

// export default Messages;

//STARTER CODE OF THIS FILE
import React, { useEffect, useRef } from "react";
import Message from "./Message";
import useGetMessage from "../../../hooks/useGetMessage";
import MessageSkeleton from "../../skeletons/MessageSkeleton";
import useListenMessages from "../../../hooks/useListenMessages";

const Messages = () => {
  const { loading, messages } = useGetMessage();

  useListenMessages();

  const lastMessageRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }, [messages]);

  return (
    <div className="px-4 flex-1 overflow-auto">
      {loading && [...Array(4)].map((_, idx) => <MessageSkeleton key={idx} />)}

      {/* If loading competed then run these */}
      {!loading &&
        messages.lenght !== 0 &&
        messages.map((msg) => (
          <div ref={lastMessageRef}>
            <Message key={msg._id} msg={msg} />
          </div>
        ))}

      {!loading && messages.length === 0 && (
        <p className="text-center text-[#FFA500]">
          Send a Template Message to start the conversation
        </p>
      )}
    </div>
  );
};

export default Messages;
