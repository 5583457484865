import { useCallback, useEffect, useState } from "react";
import "./flow.css";
import ReactFlow, {
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MiniMap,
  Controls,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";

import TopbarFlow from "./components/topbar/TopbarFlow";
import Sidebar from "./components/sidebar/Sidebar";
import TBCustomNode from "./components/customNode's/TBCustomNode";
import CustomEdge from "./components/customEdge/CustomEdge";
import MediaCustomNode from "./components/customNode's/MediaCustomNode";
import ListCustomNode from "./components/customNode's/ListCustomNode";
import SingleCustomNode from "./components/customNode's/SingleCustomNode";
import MultiPCustomNode from "./components/customNode's/MultiPCustomNode";
import ContactCostomNode from "./components/customNode's/ContactHumanCustomNode";
import InitialNode from "./components/customNode's/InitialNode";

const initialNodes = [
  {
    id: "1",
    type: "initialNode",
    data: { label: "Flow Start", keywords: "test" },
    position: { x: 250, y: 5 },
  },
];

const initialEdges = [];

const nodeTypes = {
  initialNode: InitialNode,
  textUpdater: TBCustomNode,
  mediaUpdater: MediaCustomNode,
  listUpdater: ListCustomNode,
  singleProductUpdater: SingleCustomNode,
  multiProductUpdater: MultiPCustomNode,
  contactUpdater: ContactCostomNode,
};

function Flow() {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  useEffect(() => {
    const savedNodes = JSON.parse(localStorage.getItem("flowNodes") || "[]");
    const savedEdges = JSON.parse(localStorage.getItem("flowEdges") || "[]");
    setNodes(savedNodes);
    setEdges(savedEdges);
  }, []);

  const saveChanges = () => {
    localStorage.setItem("flowNodes", JSON.stringify(nodes));
    localStorage.setItem("flowEdges", JSON.stringify(edges));
  };

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onNodeInputChange = useCallback((id, name, value) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id
          ? { ...node, data: { ...node.data, [name]: value } }
          : node
      )
    );
  }, []);

  const addNode = useCallback(
    (type, data = {}) => {
      const newNode = {
        id: (nodes.length + 1).toString(),
        type: type,
        position: { x: 500, y: 500 },
        data: {
          label: `${type} Node`,
          ...data,
          onInputChange: onNodeInputChange,
        },
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [nodes, setNodes, onNodeInputChange]
  );

  const defaultEdgeOptions = {
    animated: true,
    style: { stroke: "#008069" },
    type: "custom",
  };

  const edgeTypes = {
    custom: CustomEdge,
  };

  const connectionLineStyle = { stroke: "#008069" };

  return (
    <div className="h-[100vh] w-full relative flex">
      <Sidebar onAddNode={addNode} />
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onConnect={onConnect}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        connectionLineStyle={connectionLineStyle}
        edgeTypes={edgeTypes}
        fitView
      >
        <TopbarFlow saveChanges={saveChanges} />
        <Background />
        <Controls />
        <MiniMap zoomable pannable />
      </ReactFlow>
    </div>
  );
}

export default Flow;
