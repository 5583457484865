import { useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
// import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TiFlowMerge } from "react-icons/ti";
import CampaignIcon from "@mui/icons-material/Campaign";
import { PersonOutlineOutlined } from "@mui/icons-material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { CgLogOut } from "react-icons/cg";
import LogoutIcon from "@mui/icons-material/Logout";
import { BiLogOutCircle } from "react-icons/bi";
import useLogOut from "../../../hooks/useLogOut";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MyProSidebar = () => {
  const { loading, logOut } = useLogOut();

  const onHadleLogOut = async () => {
    await logOut();
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { collapseSidebar, toggleSidebar, collapsed } = useProSidebar();
  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        color: "white",
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          // padding: "5px 35px 5px 20px !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
      }}
    >
      <Sidebar breakPoint="md" backgroundColor={colors.primary[400]}>
        <Menu iconshape="square">
          <MenuItem
            style={{
              margin: "10px 0 10px 0",
              color: colors.grey[100],
            }}
            icon={collapsed ? <MenuOutlinedIcon /> : undefined}
            onClick={() => {
              collapseSidebar();
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt="20px"
              >

                {/* <Typography variant="h3">KoreGrow</Typography> */}
                <IconButton onClick={() => toggleSidebar()}>
                  {/* <CloseOutlinedIcon /> */}
                  <img className="w-14 mx-auto" src="/assets/Logo.png" alt="" />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!collapsed && (
            <Box>
              {/* <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  "& .avater-image": {
                    backgroundColor: colors.primary[500],
                  },
                }}
              >
                <img
                  className="avater-image"
                  alt="profile user"
                  width="100px"
                  height="100px"
                  src={"../../assets/koreGrowLogo.jpg"}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box> */}
              {/* <Box textAlign="center">
                <Typography
                  variant="h2"
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  KoreGrow
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="h5"
                  color={colors.greenAccent[500]}
                >
                  Prince Prajapati
                </Typography>
              </Box> */}
            </Box>
          )}
          <Box paddingLeft={collapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              color={colors.grey[700]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography> */}
            <Item
              title="Live Chat"
              to="/livechat"
              icon={<ForumOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* History */}
            {/* <Item
              title="History"
              to="/history"
              icon={<RestoreOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Contacts"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Campaigns"
              to="/campaigns"
              icon={<CampaignIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              color={colors.grey[700]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography> */}
            <Item
              title="Profile"
              to="/profile"
              icon={<PersonOutlineOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            {/* <Typography
              variant="h6"
              color={colors.grey[700]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Manage
            </Typography> */}
            <Item
              title="Create Template"
              to="/template"
              icon={<PolylineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Opt-In/Out"
              to="/optSystem"
              icon={<FilterAltOffIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Item
              title="User Concern"
              to="/UserConcerns"
              icon={<VerifiedUserIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* 
            <Item
              title="Biling & Usage"
              to="/biling"
              icon={<AccountBalanceWalletIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Flow"
              to="/flow"
              icon={<TiFlowMerge />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="E-Commerce"
              to="/ecommerce"
              icon={<AddBusinessOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="Analytics"
              to="/analytics"
              icon={<QueryStatsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="API Key"
              to="/apikey"
              icon={<VpnKeyIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="API Key"
              to="/apikey"
              icon={<VpnKeyIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <MenuItem
              disabled={loading}
              onClick={onHadleLogOut}
              style={{ color: colors.grey[100] }}
              icon={<LogoutIcon />}
            >
              {loading ? (
                <span className="loading loading-dots"></span>
              ) : (
                <Typography>Log Out</Typography>
              )}
            </MenuItem>
          </Box>
          {/* <button
            disabled={loading}
            className="btn w-full border-none rounded-none bg-[#122D30] text-white font-bold p-2 pe-20"
            onClick={onHadleLogOut}
          >
            {loading ? (
              <span className="loading loading-dots"></span>
            ) : (
              <>
                <span className="text-xl text-red-500">
                  <BiLogOutCircle />
                </span>
                Log Out
              </>
            )}
          </button> */}
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
