// import React from "react";
// import TableHeader from "../../components/template/TableHeader";
// import List from "../../components/template/List";

// function Pending() {
//   const listDetails = [
//     // {
//     //   row1: "sample_template_message",
//     //   row2: "UTILITY",
//     //   row3: "APPROVED",
//     //   row4: "Text",
//     //   row5: "high",
//     //   row6: "june",
//     //   boxContent1:
//     //     "The first alwys special like your first salary,your first car & your first date",
//     //   boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
//     //   boxContent3:
//     //     " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
//     //   boxContent4: "just use code :{{3}}",
//     //   boxContent5: "reply Stop to unsubscribe",
//     //   id: "1",
//     // },
//     // {
//     //   row1: "sample_template_message",
//     //   row2: "UTILITY",
//     //   row3: "APPROVED",
//     //   row4: "Text",
//     //   row5: "high",
//     //   row6: "june",
//     //   boxContent1:
//     //     "The first alwys special like your first salary,your first car & your first date",
//     //   boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
//     //   boxContent3:
//     //     " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
//     //   boxContent4: "just use code :{{3}}",
//     //   boxContent5: "reply Stop to unsubscribe",
//     //   id: "2",
//     // },
//     // {
//     //   row1: "sample_template_message",
//     //   row2: "UTILITY",
//     //   row3: "APPROVED",
//     //   row4: "Text",
//     //   row5: "high",
//     //   row6: "june",
//     //   boxContent1:
//     //     "The first alwys special like your first salary,your first car & your first date",
//     //   boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
//     //   boxContent3:
//     //     " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
//     //   boxContent4: "just use code :{{3}}",
//     //   boxContent5: "reply Stop to unsubscribe",
//     //   id: "3",
//     // },
//     // {
//     //   row1: "sample_template_message",
//     //   row2: "UTILITY",
//     //   row3: "APPROVED",
//     //   row4: "Text",
//     //   row5: "high",
//     //   row6: "june",
//     //   boxContent1:
//     //     "The first alwys special like your first salary,your first car & your first date",
//     //   boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
//     //   boxContent3:
//     //     " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
//     //   boxContent4: "just use code :{{3}}",
//     //   boxContent5: "reply Stop to unsubscribe",
//     //   id: "4",
//     // },
//     // {
//     //   row1: "sample_template_message",
//     //   row2: "UTILITY",
//     //   row3: "APPROVED",
//     //   row4: "Text",
//     //   row5: "high",
//     //   row6: "june",
//     //   boxContent1:
//     //     "The first alwys special like your first salary,your first car & your first date",
//     //   boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
//     //   boxContent3:
//     //     " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
//     //   boxContent4: "just use code :{{3}}",
//     //   boxContent5: "reply Stop to unsubscribe",
//     //   id: "5",
//     // },
//     // {
//     //   row1: "sample_template_message",
//     //   row2: "UTILITY",
//     //   row3: "APPROVED",
//     //   row4: "Text",
//     //   row5: "high",
//     //   row6: "june",
//     //   boxContent1:
//     //     "The first alwys special like your first salary,your first car & your first date",
//     //   boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
//     //   boxContent3:
//     //     " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
//     //   boxContent4: "just use code :{{3}}",
//     //   boxContent5: "reply Stop to unsubscribe",
//     //   id: "6",
//     // },
//   ];
//   return (
//     <>
//       <div className="container mx-auto">
//         <table className="w-11/12  my-10 rounded-md mx-auto ">
//           <TableHeader />

//           {listDetails.length >= 0 &&
//             listDetails.map((data) => (
//               <List
//                 key={data.id}
//                 row1={data.row1}
//                 row2={data.row2}
//                 row3={data.row3}
//                 row4={data.row4}
//                 row5={data.row5}
//                 row6={data.row6}
//                 boxContent1={data.boxContent1}
//                 boxContent2={data.boxContent2}
//                 boxContent3={data.boxContent3}
//                 boxContent4={data.boxContent4}
//                 boxContent5={data.boxContent5}
//               />
//             ))}
//         </table>

//         {listDetails.length === 0 && (
//           <div className="flex justify-center items-center w-full h-20">
//             <div className="">No templates yet !</div>
//           </div>
//         )}
//       </div>
//     </>
//   );
// }

// export default Pending;

import React from 'react';
import { useFetchStatus } from '../../hooks/useManagetemplate';

import TableHeader from "../../components/template/TableHeader";
import List from "../../components/template/List";


function Pending() {
  const { isstatus: PendingTemplate, load: loadpending } = useFetchStatus("PENDING");
// console.log("loader template is:",PendingTemplate);

 


  return (
    <>
     {/* <div className="container mx-auto">
      <table className="w-11/12 my-10 rounded-md mx-auto">
        <TableHeader />
        {loadpending ? (
          <span className="loading loading-spinner"></span>
        ) : (
          PendingTemplate.map((data) => (
            <List
              key={data._id}
              row1={data.configData?.name}
              row2={data.configData?.category}
              row3={data.templateStatus}
              row5={data.id}
              time = {data.createdAt}
               row4={data.configData?.components[0]?.format}
              header = {data.configData.components[0]?.text}
              row6={data.row6}
              boxContent2={data.configData?.components[1]?.text}
              bodyText={data.configData?.components[1]?.example?.body_text}
              boxContent4={data.configData?.components[2]?.text}
              buttons={data.configData?.components[3]?.buttons || []}
            />
          ))
        )}
      </table>
    </div> */}


<div className="container mx-auto">
      <table className="w-11/12 my-10 rounded-md mx-auto">
        <TableHeader />
        {loadpending ? (
          <span className="loading loading-spinner"></span>
        ) : (
          PendingTemplate.map((data) => {
            let templateFormat;
            let headerText;
            let bodyText;
            let footer;
            let totalbutton = [];

            const components = data.configData.components;

            for (let j = 0; j < components.length; j++) {
              const component = components[j];


              console.log("component lookes like this :",component)
              if (component.type === "HEADER" && component.format) {
                templateFormat = component.format;
                
                if (component.format === "Text") {
                  headerText = component.text;
                  console.log("header looks like this in map function:", headerText);
                }
              } else if (component.type === "BODY" && component.text) {
                bodyText = component.text;
              } else if (component.type === "FOOTER" && component.text) {
                footer = component.text;
             
              } else if (component.type === "BUTTONS" && component.buttons) {
                totalbutton = totalbutton.concat(component.buttons);
              }
            }

            return (
              <List
                key={data._id}
                row1={data.configData?.name}
                row2={data.configData?.category}
                row3={data.templateStatus}
                row4={templateFormat}
                row5={data.id}
                row6={data.row6}
                time={data.createdAt}
                header={headerText}
                boxContent2={bodyText}
                boxContent4={footer}
                buttons={totalbutton}
              />
            );
          })
        )}
      </table>
    </div>

    </>

  );
}





export default Pending;
