import React, { useEffect, useState } from "react";
import { PiWarningCircleLight } from "react-icons/pi";
import { IoImageSharp, IoDocumentText, IoPlayCircle } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaArrowRightLong, FaArrowUpRightFromSquare } from "react-icons/fa6";
import { MdOutlineCall } from "react-icons/md";
import { HiLink } from "react-icons/hi2";
import { tokens } from "../../theme";

import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Sent from "../../pages/campaigns/Sent";
import Broadcast from "../../pages/contacts/Broadcast";

function StatusBox({ children }) {
    return <div className="h-32 w-[45%] shadow-lg p-4">{children}</div>;
}

function BoxHeading({ data }) {
    return (
        <div>
            <h2 className="text-sm flex items-center gap-2 text-gray-500">
                {data.title}
                <span>
                    <PiWarningCircleLight className="text-sm text-gray-400" />
                </span>
            </h2>
            <p className="text-xl font-bold text-black">{data.count}</p>
        </div>
    );
}

function Campaign({
    firstdiv,
    secoundiv,
    lastDiv,
    fullData,
    template,
    tableData,
}) {
    console.log("table data looks likes this :", tableData);
    const [showtemplate, setTemplate] = useState([]);

    useEffect(() => {
        setTemplate(template);
    }, [template]);
   

  

    //list for all data
    const [isheader, setheader] = useState("");
    const [isbody, setbody] = useState("");
    const [isfooter, setfooter] = useState("");
    const [buttons, setbuttons] = useState([]);
    const [istitle, setTitle] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [selectedmedia, setselectedmedia] = useState(null);
    const [istemplateid, settemplateid] = useState("");
    useEffect(() => {
        if (showtemplate?.components) {
            const components = showtemplate.components;
            let templateFormat = "";
            let headerText = "";
            let bodyText = "";
            let footer = "";
            let totalbutton = [];

            for (let j = 0; j < components.length; j++) {
                const component = components[j];

                console.log("Component looks like this:", component);

                if (component.type === "HEADER" && component.format) {
                    templateFormat = component.format;

                    if (component.format === "Text") {
                        headerText = component.text;
                        console.log("Header looks like this in map function:", headerText);
                    }
                } else if (component.type === "BODY" && component.text) {
                    bodyText = component.text;
                } else if (component.type === "FOOTER" && component.text) {
                    footer = component.text;
                } else if (component.type === "BUTTONS" && component.buttons) {
                    totalbutton = totalbutton.concat(component.buttons);
                }
            }

            // Update the state with the extracted values
            setheader(templateFormat);
            setbody(bodyText);
            setfooter(footer);
            setbuttons(totalbutton);
            setTitle(headerText); // Assuming Title is set from headerText
        }
    }, [showtemplate]);

    let content = null;

    if (isheader?.toUpperCase() === "IMAGE") {
        content = <IoImageSharp className="h-40 w-80 bg-orange-300 rounded-md" />;
    } else if (isheader?.toUpperCase() === "VIDEO") {
        content = (
            <div className="text-4xl text-blue-600 h-40 w-72 rounded-md">
                <IoPlayCircle className="h-40 w-72" />
            </div>
        );
    } else if (isheader?.toUpperCase() === "DOCUMENT") {
        content = <IoDocumentText className="h-40 w-72 text-red-400 " />;
    } else if (isheader?.toUpperCase() === "LOCATION") {
        content = (
            <div className="text-3xl text-red-600 h-40 w-72 rounded-md">
                <FaLocationDot className="h-40 w-72" />
            </div>
        );
    } else if (isheader?.toUpperCase() === "TEXT") {
        content = (
            <div className=" w-full bg-white">
                <p className="font-whatsapp-bold font-bold ml-2">
                    {istitle.toLowerCase()}
                </p>
            </div>
        );
    }

    //code for table data
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedContact, setselecetedContact] = useState([]);
    const [selectedlist, setselectedlist] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [
        { field: "id", headerName: "Id", width: 100 },
        {
            field: "name",
            headerName: "Name",
            cellClassName: "name-column--cell",
            width: 200,
        },
        { field: "phone", headerName: "Phone Number", width: 200 },
        { field: "createdAt", headerName: "Created At", width: 200 },
       
        { field: "reason", headerName: "Failed reason", width: 400 },
    ];    

    const [mockDataContacts, setMockDataContacts] = useState([]);

    useEffect(() => {
        if (Array.isArray(tableData) && tableData.length > 0) {
            // Check if tableData is an array and has at least one item

            // Case where there is failed data
            if (tableData[0].contacts) {
                setMockDataContacts(
                    tableData.map((contact, index) => ({
                        id: index + 1,
                        phone: contact.contacts?.mobile_Number?.trim() || "n/A", // Handle phone number from nested contacts
                        reason: contact.reason, // Add the reason field if available
                        ...contact.contacts, // Spread properties from the nested contacts field
                    }))
                );
            } else {
                // If tableData is an array of contacts (no nested contacts property)
                setMockDataContacts(
                    tableData.map((contact, index) => ({
                        id: index + 1,
                        phone: contact.mobile_Number?.trim() || "N/A", //Same phone handling as above
                        ...contact, // Spread the remaining contact properties
                    }))
                );
            }
        }
    }, [tableData]);
   

    const handleSelectionChange = (newSelection) => {
        if (tableData && tableData.length > 0) {
            setSelectionModel(newSelection);
            const selectedRows = newSelection.map((id) =>
                mockDataContacts.find((row) => row.id === id)
            );

            console.log("Selected rows look like this:", selectedRows);
            const mobileNumbers = selectedRows.map((entry) => entry.mobile_Number);
            const selectedid = selectedRows.map((select) => select._id);
            console.log("selected mobile number looks likes this :", mobileNumbers);
            setselecetedContact(mobileNumbers);
            setselectedlist(selectedid);
        }
    };
    return (
        <>
            <div className="w-full flex gap-3">
                <div className="w-[30%]  shadow-lg">
                    <div className="w-80 card mx-auto bg-white text-black rounded-md shadow-md p-5 mt-7">
                        <div className="p-1 rounded-md bg-transparent">
                            <div className="w-full rounded-md overflow-hidden mb-2">
                                <figure>{content}</figure>
                                <div className="w-full p-2 text-sm bg-white font--mt-1">
                                    <p className="font-whatsapp">{isbody}</p>
                                    <div className="text-xs text-zinc-500 font-normal">
                                        {isfooter}
                                    </div>
                                </div>
                            </div>
                            {buttons.map((button, index) => (
                                <div key={index}>
                                    {button.type === "url" && (
                                        <div className="h-9 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                            <FaArrowRightLong className="text-sm" /> {button.text}
                                        </div>
                                    )}
                                    {button.type === "PHONE_NUMBER" && (
                                        <div className="h-9 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                            <MdOutlineCall className="text-sm" /> {button.text}
                                        </div>
                                    )}
                                    {button.type === "QUICK_REPLY" && (
                                        <div className="h-9 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                            <HiLink className="text-sm" /> {button.text}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-[65%] h-screen shadow-lg">
                    <div className="flex mt-6 gap-3">
                        <StatusBox>
                            <BoxHeading data={firstdiv} />
                        </StatusBox>
                        <StatusBox>
                            <BoxHeading data={secoundiv} />
                        </StatusBox>
                        <StatusBox>
                            <BoxHeading data={lastDiv} />
                        </StatusBox>
                    </div>
                    <div className="mx-auto h-40 shadow-lg">
                        <div className="flex h-auto">
                            {fullData.map((data, index) => (
                                <div key={index} className=" h-28 w-[25%]  p-4">
                                    <BoxHeading data={data} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <p className="text-sm text-gray-500  mt-5 ml-2">Campaign Messages </p>
                </div>
                <div className="mx-auto"></div>
            </div>
            <div >

                {tableData && tableData.length > 0 ? (
                    <Box
                        m="8px 0 0 0"
                        width="83.33%"
                        marginX="auto"
                        height="80vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                color: "white",
                            },
                            "& .css-wc9pnx-MuiDataGrid-root .MuiDataGrid-cell:focus": {
                                outline: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#0A474C",
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller:nth-of-type(odd)": {
                                backgroundColor: "white",
                            },
                            "& .MuiCheckbox-root": {
                                color: `#2cb59a !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `#0A474C !important`,
                            },
                            "& .PrivateSwitchBase-input": {
                                color: `#0A474C !important`,
                            },
                            "& .css-1fe4m6k .MuiCheckbox-root": {
                                color: `red !important`,
                            },
                            "& .css-wc9pnx-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
                            {
                                outline: `none`,
                            },
                            "& .css-204u17-MuiDataGrid-main": {
                                width: "100% !important",
                                marginX: "auto !important",
                            },
                            "& .css-1j9kmqg-MuiDataGrid-toolbarContainer": {
                                width: "100% !important",
                                marginX: "auto !important",
                                marginBottom: "10px !important",
                            },
                            "& .MuiDataGrid-footerContainer": {
                                position: "fixed !important",
                                bottom: "0",
                                left: "0",
                                gap: "132px",
                                width: "100% !important",
                                overflow: "hidden",
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            },
                        }}
                    >

                        <div className="mx-auto flex justify-end mr-40 mt-14">
                            {selectionModel?.length > 0 ?  (
                                <Broadcast
                                    mobileNumbers={selectedContact}
                                    selectedList={selectedlist}
                                />
                            ) : (
                                <div
                                    className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]"
                                    disabled
                                >
                                    Broadcast
                                </div>
                            )}
                        </div>
                        <DataGrid
                            rows={mockDataContacts}
                            columns={columns}
                            components={{ Toolbar: GridToolbar }}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection
                            onSelectionModelChange={handleSelectionChange}
                            selectionModel={selectionModel}
                        />
                    </Box>
                ) : (
                    <div></div>
                )}
            </div>
        </>
    );
}

export default Campaign;
