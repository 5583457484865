import React, { useEffect, useState } from "react";
import "./contact.css";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./customStyles.css"; // Your custom styles

import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataContacts } from "../../data/mockData";
import GlobalHeader from "../../components/GlobalHeader";
// import QuickGuilde from "../../components/QuickGuilde";
import useGetContacts from "../../hooks/useGetContacts";
import ImportCon from "./ImportCon";
// import CountryCode from "../../components/NumberCode";
import useOneContactCr from "../../hooks/useOneContactCr";
import Broadcast from "./Broadcast";
import { FaGoogle } from "react-icons/fa6";

const Contacts = () => {
  const { loadingContact, refreshContacts, contacts } = useGetContacts();

  console.log("conatcts looks likes in conatct pages :", contacts);
  const [mockDataContacts, setMockDataContacts] = useState([]);

  const [testRefresh, settestRefresh] = useState(true);

  const testRefreshCont = () => {
    refreshContacts(); // Get refreshContacts function // re-render when the contacts are uploaded
    settestRefresh((prev) => !prev);
  };

  useEffect(() => {
    // Map contacts to mockDataContacts when contacts change
    setMockDataContacts(
      contacts.map((contact, index) => ({
        id: index + 1,
        phone: contact.mobile_Number,
        ...contact,
      }))
    );
  }, [testRefresh, contacts]); // Trigger effect on changes in contacts

  // console.log("contactShow", mockDataContacts);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "Id", width: 100 },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 200,
    },
    { field: "phone", headerName: "Phone Number", width: 200 },
    // { field: "tags", headerName: "Tags", width: 200 },
    { field: "source", headerName: "Source", width: 200 },
    { field: "tags", headerName: "Tags", width: 200 },
    // { field: "state", headerName: "State", width: 200 },
    // { field: "intervenedBy", headerName: "Intervened By", width: 200 },
    // { field: "lastActive", headerName: "Last Active", width: 200 },
    { field: "createdAt", headerName: "Created At", width: 200 },
    // { field: "firstMessage", headerName: "First Message", width: 200 },
    { field: "incoming", headerName: "Incoming", width: 100 },
    // { field: "optedIn", headerName: "Opted In", width: 200 },
    // { field: "MAU Status", headerName: "MAU Status", width: 200 },
    // {
    //   field: "WAConversationStatus",
    //   headerName: "WA Conversation Status",
    //   width: 200,
    // },
  ];

  const DetailsOfGlobalHeader = {
    Title: "Contacts",
    Details: true,
  };

  const quickReplyObj = {
    text1:
      "Import contact, create audience & launch campaign, all from one place.",
    text2:
      "Launch a campaign now to initiate new conversations with users on WhatsApp.",
    link1: "Import upto 2 lakh contacts in one go",
    link2: "Watch Tutorial",
  };

  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedContact, setselecetedContact] = useState([]);
  const [selectedlist, setselectedlist] = useState([]);

  const handleSelectionChange = (newSelection) => {
    setSelectionModel(newSelection);
    const selectedRows = newSelection.map((id) =>
      mockDataContacts.find((row) => row.id === id)
    );
    // console.log("Selected rows data: ", selectedRows);
    // console.log("selected name looks like this :",selectedRows._id);

    const mobileNumbers = selectedRows.map((entry) => entry.mobile_Number);
    const selectedid = selectedRows.map((select) => select._id);
    // console.log("Numbers selected in array are:", mobileNumbers);
    setselecetedContact(mobileNumbers);
    setselectedlist(selectedid);

    // console.log("selected list looks like :",selectedlist)

    // Implement your custom logic here
    // console.log("this is selected contact",selectedName)
  };

  const handleGoogleSheet = () => {
    window.location.href = "http://localhost:5000/api/sheets/";
  };

  return (
    <>
      <GlobalHeader item={DetailsOfGlobalHeader} />
      {/* <QuickGuilde data={quickReplyObj} /> */}
      <div className="mt-6 relative">
        <Box marginX={"auto"} marginBottom={10}>
          <div className=" absolute z-50 -top-1  right-28 flex gap-2 items-center">
            {selectionModel?.length > 0 ? (
              <Broadcast
                mobileNumbers={selectedContact}
                selectedList={selectedlist}
              />
            ) : (
              <div
                className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]"
                disabled
              >
                Broadcast
              </div>
            )}
            <div
              className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]"
              onClick={handleGoogleSheet}
            >
              <span>
                <FaGoogle />
              </span>
              Sheet Connector
            </div>
            <AddContact testRefreshCont={testRefreshCont} />
            <ImportCon testRefreshCont={testRefreshCont} />
          </div>
          <Box
            m="8px 0 0 0"
            width="83.33%"
            marginX="auto"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
              },
              "& .css-wc9pnx-MuiDataGrid-root .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#0A474C",
                borderBottom: "none",
              },
              // "& .MuiDataGrid-virtualScroller": {
              //   backgroundColor: "#EBF5F3",
              // },
              "& .MuiDataGrid-virtualScroller:nth-of-type(odd)": {
                backgroundColor: "white",
              },
              "& .MuiCheckbox-root": {
                color: `#2cb59a !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `#0A474C !important`,
              },
              "& .PrivateSwitchBase-input": {
                color: `#0A474C !important`,
              },
              "& .css-1fe4m6k .MuiCheckbox-root": {
                color: `red !important`,
              },
              "& .css-wc9pnx-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
                {
                  outline: `none`,
                },
              "& .css-204u17-MuiDataGrid-main": {
                width: "100% !important",
                marginX: "auto !important",
              },
              "& .css-1j9kmqg-MuiDataGrid-toolbarContainer": {
                width: "100% !important",
                marginX: "auto !important",
                marginBottom: "10px !important",
              },
              "& .MuiDataGrid-footerContainer": {
                position: "fixed !important",
                bottom: "0",
                left: "0",
                gap: "132px",
                width: "100% !important",
                overflow: "hidden",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            <DataGrid
              rows={mockDataContacts}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
              onSelectionModelChange={handleSelectionChange}
              selectionModel={selectionModel}
              loading={loadingContact}
            />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Contacts;

function AddContact({ testRefreshCont }) {
  const [value, setValue] = useState();
  const [inputs, setInputs] = useState({
    name: "",
    tags: "",
    source: "",
  });
  const { loading, CreateContact } = useOneContactCr();

  const onHadleSubmit = async (e) => {
    e.preventDefault();

    try {
      await CreateContact(inputs, value);
      testRefreshCont(); // Trigger refresh after upload
      document.getElementById("my_modal_3").close(); //
    } catch (error) {
      console.error("Error creating contact:", error);
    } finally {
      setInputs({
        name: "",
        tags: "",
        source: "",
      });
      setValue(null);
    }
  };
  return (
    <>
      <button
        className="btn btn-sm bg-transparent text-[#0A474C] border-[#0A474C] hover:bg-[#EDF0F0] rounded-md "
        onClick={() => document.getElementById("my_modal_3").showModal()}
        disabled={loading}
      >
        {loading ? (
          <span className="loading loading-dots"></span>
        ) : (
          "Add Contact"
        )}
      </button>

      {/* dialog box */}
      <form>
        <dialog id="my_modal_3" className="modal">
          <div className="modal-box min-w-[42vw] p-10 bg-white text-black">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn btn-sm border-none outline-none btn-ghost absolute right-2 top-2">
                ✕
              </button>
            </form>
            <div className="text-xl">Create Contact</div>

            <div className="mt-10  p-5">
              <div className="flex justify-between items-center mb-5">
                <label htmlFor="name" className="text-lg font-medium">
                  Name :
                </label>
                <input
                  id="name"
                  type="text"
                  value={inputs.name}
                  onChange={(e) => {
                    setInputs({ ...inputs, name: e.target.value });
                  }}
                  placeholder="Enter your name"
                  className="bg-white input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              {/* 
              <div className="flex justify-between items-center mb-5">
                <label htmlFor="number" className="text-lg font-medium">
                  Mobile Number :
                </label>

                <input
                  id="number"
                  type="text"
                  value={inputs.number}
                  onChange={(e) => {
                    setInputs({ ...inputs, number: e.target.value });
                  }}
                  className="bg-white input input-bordered input-sm w-full max-w-xs"
                  placeholder="ex.   +91 73XXXXXX98"
                />
              </div> */}

              <div className="flex justify-between items-center mb-5">
                <label htmlFor="number" className="text-lg font-medium">
                  Mobile Number :
                </label>

                <div className="phone-input-container w-full max-w-xs">
                  <PhoneInput
                    international
                    defaultCountry="RU"
                    value={value}
                    onChange={setValue}
                  />
                </div>
              </div>

              <div className="flex justify-between items-center mb-5">
                <label htmlFor="tag" className="text-lg font-medium">
                  Tags :
                </label>
                <input
                  value={inputs.tags}
                  onChange={(e) => {
                    setInputs({ ...inputs, tags: e.target.value });
                  }}
                  id="tag"
                  type="text"
                  className="bg-white input input-bordered input-sm w-full max-w-xs"
                />
              </div>

              <div className="flex justify-between items-center mb-5">
                <label htmlFor="Source" className="text-lg font-medium">
                  Source :
                </label>
                <input
                  value={inputs.source}
                  onChange={(e) => {
                    setInputs({ ...inputs, source: e.target.value });
                  }}
                  id="Source"
                  type="text"
                  className="bg-white input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            </div>
            <form method="dialog">
              <button
                type="submit"
                onClick={onHadleSubmit}
                className="btn btn-sm w-full hover:bg-[#10282a] bg-[#0A474C] text-white border-none text-end"
                disabled={loading}
              >
                {loading ? (
                  <span className="loading loading-dots"></span>
                ) : (
                  "Add Contact"
                )}
              </button>
            </form>
          </div>
        </dialog>
      </form>
    </>
  );
}
