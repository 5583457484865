import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useCampaign = () => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const getCampaigns = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/whatsapp/details/campaigns");
      setCampaigns(res.data);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  return { loading, campaigns };
};
 //  this is will get all campain forparrticular 
const useAnaCam = ({id,category}) => {

  const [loading, setLoading] = useState(false);
  const [camAna, setCamAna] = useState({});

  const getAnaCam = async () => {

    try {
      setLoading(true);
      const res = await axios.get(`/whatsapp/details/campaigns/${id}?detail_of=${category}`);
      console.log(res.data);
      setCamAna(res.data);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && category) {  
      getAnaCam();
    }
  }, [id, category]);
  console.log("camp in hooks lokks likes :",camAna);

  return { loading, camAna };

};  

export { useCampaign, useAnaCam };
