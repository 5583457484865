import React, { useEffect } from "react";
import TableHeader from "../../components/template/TableHeader";
import List from "../../components/template/List";
import { useFetchTemplates } from "../../hooks/useManagetemplate";

function Marketing() {
  const { templates: marketingTemplates, loading: loadingMarketing } =
    useFetchTemplates("MARKETING", "APPROVED");
  // console.log("template id looks like :",marketingTemplates);

  return (
    // <div className="container mx-auto">
    //   <table className="w-11/12 my-10 rounded-md mx-auto">
    //     <TableHeader />

    //     {loadingMarketing ? (
    //       <span className="loading loading-spinner"></span>
    //     ) : (
    //       marketingTemplates.map((data) => (
    //         <List
    //           key={data._id}
    //           row1={data.configData?.name}
    //           row2={data.configData?.category}
    //           row3={data.templateStatus}
    //           row4={data.configData?.components[0]?.format}
    //           header={data.configData.components[0]?.text}
    //           time={data.createdAt}
    //           row5={data.id}
    //           row6={data.row6}
    //           boxContent2={data.configData?.components[1]?.text}
    //           bodyText={data.configData?.components[1]?.example?.body_text}
    //           boxContent4={data.configData?.components[2]?.text}
    //           buttons={data.configData?.components[3]?.buttons || []}
    //         />
    //       ))
    //     )}
    //   </table>
    // </div>

<div className="container mx-auto">
      <table className="w-11/12 my-10 rounded-md mx-auto">
        <TableHeader />
        {loadingMarketing ? (
          <span className="loading loading-spinner"></span>
        ) : (
          marketingTemplates.map((data) => {
            let templateFormat;
            let headerText;
            let bodyText;
            let footer;
            let totalbutton = [];

            const components = data.configData.components;

            for (let j = 0; j < components.length; j++) {
              const component = components[j];


              console.log("component lookes like this :",component)
              if (component.type === "HEADER" && component.format) {
                templateFormat = component.format;
                
                if (component.format === "Text") {
                  headerText = component.text;
                  console.log("header looks like this in map function:", headerText);
                }
              } else if (component.type === "BODY" && component.text) {
                bodyText = component.text;
              } else if (component.type === "FOOTER" && component.text) {
                footer = component.text;
             
              } else if (component.type === "BUTTONS" && component.buttons) {
                totalbutton = totalbutton.concat(component.buttons);
              }
            }

            return (
              <List
                key={data._id}
                row1={data.configData?.name}
                row2={data.configData?.category}
                row3={data.templateStatus}
                row4={templateFormat}
                row5={data.id}
                row6={data.row6}
                time={data.createdAt}
                header={headerText}
                boxContent2={bodyText}
                boxContent4={footer}
                buttons={totalbutton}
              />
            );
          })
        )}
      </table>
    </div>


  );
}

export default Marketing;
