import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";

import Dashboard from "./pages/dashboard";
import LiveChat from "./pages/liveChat";
import Contacts from "./pages/contacts";
// import Form from "./pages/form";
import Calendar from "./pages/calendar";
import FAQ from "./pages/faq";
import SignUp from "./pages/signUp";
import Login from "./pages/login";
import Flow from "./pages/flow";
import Campaigns from "./pages/campaigns";
import ECommerce from "./pages/ecommerce";
import AppStore from "./components/eCommerce/AppStore";
import { ShopifySS, WooCommerceSS } from "./components/eCommerce/StoreSetup";
import AnalyzeBar from "./pages/analyzeBar";
import ApiKey from "./pages/apiKey";
import Template from "./pages/template";
import Explore from "./pages/template/Explore";
import AllList from "./pages/template/AllList";
import Draft from "./pages/template/Draft";
import ActionRequird from "./pages/template/ActionRequird";
import Pending from "./pages/template/Pending";
import Approval from "./pages/template/Approval";
import Profile from "./pages/profile";
import CreateTemplate from "./components/template/CreateTemplate";
import { Toaster } from "react-hot-toast";
import "./toast.css";
import { UseAuthContext } from "./context/AuthContext";
import { useEffect } from "react";
import Biling from "./pages/biling/Biling";
import OTP from "./pages/otp";
import Marketing from "./pages/template/Marketing";
import Utility from "./pages/template/Utility";
import Authentication from "./pages/template/Authentication";
import AnaCamp from "./components/AnaCamp";
import OptSystem from "./pages/MangeOpt/OptSystem";
import UserConcerns from "./pages/UserConcern";
import { useGetAuthDetails } from "./hooks/useLogin";
import Overview from "./pages/campaigns/Overview";
import Sent from "./pages/campaigns/Sent";
import Delivered from "./pages/campaigns/Delivered";
import Read from "./pages/campaigns/Read";
import Failed from "./pages/campaigns/Failed";
import GoogleSheet from "./pages/googleSheet";
const MainDash = () => (
  <MyProSidebarProvider>
    <div style={{ height: "100%", width: "100%" }}>
      <main>
        <Outlet />
      </main>
    </div>
  </MyProSidebarProvider>
);

// Utility function to check if a cookie exists
const checkCookieExists = (cookieName) => {
  const cookies = document.cookie.split(";");

  // console.log(cookies);
  // Iterate over all cookies
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Log each cookie being checked
    if (cookie.startsWith(`${cookieName}=`)) {
      return true;
    }
  }
  return false;
};

const App = () => {
  const { authUser, setAuthUser } = UseAuthContext();
  const { authLoading } = useGetAuthDetails();
  const [theme, colorMode] = useMode();

  useEffect(() => {
    if (!checkCookieExists("THEBOTMODE_CHECK")) {
      localStorage.removeItem("authUser");
      setAuthUser(null);
    }
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {!authLoading && (
            <Routes>
              <Route
                path="/signup"
                element={authUser ? <Navigate to={"/"} /> : <SignUp />}
              />
              <Route
                path="/login"
                element={authUser ? <Navigate to={"/"} /> : <Login />}
              />
              <Route
                path="/*"
                element={authUser ? <MainDash /> : <Navigate to={"/login"} />}
              >
                <Route path="" element={<Dashboard />} />
                <Route path="livechat" element={<LiveChat />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="campaigns" element={<Campaigns />} />
                <Route path="campaigns/analytics/:id" element={<AnaCamp />}>
                  <Route path="" element={<Overview />} />
                  <Route path="camp/sent" element={<Sent />} />
                  <Route path="camp/Delivered" element={<Delivered />} />
                  <Route path="camp/read" element={<Read />} />
                  <Route path="camp/failed" element={<Failed />} />
                </Route>
                <Route path="apikey" element={<ApiKey />} />
                <Route path="profile" element={<Profile />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="calendar" element={<Calendar />} />
                <Route path="flow" element={<Flow />} />
                <Route path="analytics" element={<AnalyzeBar />} />
                <Route path="template/create" element={<CreateTemplate />} />
                <Route path="optSystem" element={<OptSystem />} />
                <Route path="UserConcerns" element={<UserConcerns />} />

                <Route path="template/*" element={<Template />}>
                  {/* <Route path="" element={<Explore />} /> */}
                  <Route path="" element={<Marketing />} />
                  <Route path="message/Utility" element={<Utility />} />
                  {/* <Route path="message/all" element={<AllList />} />   */}
                  {/* <Route path="message/draft" element={<Draft />} /> */}
                  <Route
                    path="message/Authentication"
                    element={<Authentication />}
                  />
                  <Route path="message/pending" element={<Pending />} />
                  <Route path="message/approval" element={<Approval />} />
                  <Route path="message/action" element={<ActionRequird />} />
                </Route>

                <Route path="/*" element={<ECommerce />}>
                  <Route path="ecommerce" element={<AppStore />} />
                  <Route
                    path="ecommerce/appstore/shopify"
                    element={<ShopifySS />}
                  />
                  <Route
                    path="ecommerce/appstore/woocommerce"
                    element={<WooCommerceSS />}
                  />
                </Route>

                <Route path="biling" element={<Biling />} />
              </Route>

              <Route
                path="/forgetPass"
                element={authUser ? <Navigate to={"/"} /> : <OTP />}
              />

              <Route path="/api/auth/google" element />
              <Route path="/googleSheet" element={<GoogleSheet />} />
            </Routes>
          )}
        </ThemeProvider>
      </ColorModeContext.Provider>

      {/* toaster */}
      <Toaster/>
    </>
  );
};

export default App;
