import React, { useState } from "react";
import "./campaig.css";
import { IoMdInformationCircle } from "react-icons/io";
import BarChartIcon from "@mui/icons-material/BarChart";
import { TbReload } from "react-icons/tb";
import QuickGuilde from "../../components/QuickGuilde";
import GlobalHeader from "../../components/GlobalHeader";
import { useCampaign } from "../../hooks/useCampaign";
import { Link } from "react-router-dom";
import useMetaUserDetails from "../../zustand/useMetaUserDetails";


function Campaigns() {
  const { metaUserDetails } = useMetaUserDetails();

  console.log(metaUserDetails);

  const [tabActive, SetTabActive] = useState("All");

  const { loading, campaigns } = useCampaign();

  
  
  const onhadleActive = (val) => {
    SetTabActive(val);
  };

  const quickReplyObj = {
    text1: "Tap on any Campaign to see detailed analytics.",
    text2:
      "Launch a campaign now to initiate new conversations with users on WhatsApp.",
    link1: "Audience segregation for WhatsApp Broadcast",
    link2: "Upgrade WhatsApp Tier Limit",
  };

  const teirValue =
    metaUserDetails?.messaging_limit_tier?.toString() || "TIER_0K";
  console.log("Teir Value:", teirValue);

  function getFormattedTierResponse(teirValue) {
    const match = teirValue.match(/TIER_(\d+)K/);
    if (match && match[1]) {
      const number = parseInt(match[1]) * 1000;
      return `(${number / 1000}K/${24} Hours)`;
    } else {
      return "(0/24 Hours)";
    }
  }

  const DetailsOfGlobalHeader = {
    Title: "Campaigns",
    Details: true,
    // EndButton: " + Luanch",
  };

  return (
    <>
      <GlobalHeader item={DetailsOfGlobalHeader} />
      {/* <QuickGuilde data={quickReplyObj} /> */}
      {/* <SearchBar /> */}

      <div className={`mt-10 w-5/6 mx-auto`}>
        <div role="tablist" className="tabs tabs-bordered">
          {/* <a
            role="tab"
            className={`tab ${
              tabActive === "All" ? "tab-active text-black" : ""
            }`}
            href="#"
            onClick={() => {
              onhadleActive("All");
            }}
          >
            All
          </a> */}
          <a
            role="tab"
            className={`tab ${
              tabActive === "Broadcast" ? "tab-active text-black" : ""
            }`}
            href="#"
            onClick={() => {
              onhadleActive("Broadcast");
            }}
          >
            Broadcast
          </a>
          {/* <a
            role="tab"
            className={`tab ${
              tabActive === "API" ? "tab-active text-black" : ""
            }`}
            href="#"
            onClick={() => {
              onhadleActive("API");
            }}
          >
            API
          </a>
          <a
            role="tab"
            className={`tab ${
              tabActive === "Scheduled"
                ? "tab-active text-black active:bg-black"
                : ""
            }`}
            href="#"
            onClick={() => {
              onhadleActive("Scheduled");
            }}
          >
            Scheduled
          </a> */}
        </div>
      </div>

      <div className="mt-5 w-5/6 mx-auto">
        <div className="my-5 w-full p-5 text-[#0A474C] text-sm bg-white rounded-md grid gap-2 grid-cols-12 items-center">
          <div className="col-span-1 text-zinc-400 text-2xl">
            <BarChartIcon />
          </div>
          <div className="col-span-4">Campaign</div>
          <div className="col-span-2">Type</div>
          <div className="col-span-2">Created At</div>
          <div className="col-span-1 text-center">Status</div>
          <div className="col-span-1 text-center">Audience</div>
          {/* <div className="col-span-1 text-center">Submitted</div> */}
        </div>
        {loading ? (
          <div className="loading loading-bars"></div>
        ) : (
          campaigns.map((item) => {
            return (
              <div>
                <Campaign
                  tem_id={item._id}
                  name={item.campaignName}
                  type={item.type}
                  date={item.createdAt}
                  status={item.status}
                  audience={item.audience}
                  // submitted={item.submitted}
                />
              </div>
            );
          })
        )}
      </div>
    </>
  );
}

export default Campaigns;

function Header() {
  return (
    <div className="flex justify-between items-center bg-white px-5 py-3 shadow sticky top-0 z-50">
      <div className="text-lg font-semibold">Campaigns</div>

      <div className="flex text-sm font-normal text-[#6E6E6E] items-center">
        <div className="flex items-center me-4">
          <div className="me-1">Quality Rating</div>
          <div
            className="tooltip tooltip-accent text-xs tooltip-bottom"
            data-tip="Your WhatsApp Business Account’s quality rating (as assessed by WhatsApp) is High, as the messages that you have been sending to your customers in the last 7 days have been of good quality.
                Know More"
          >
            <IoMdInformationCircle className="text-lg" />
          </div>
          <div className="w-12 py-1 text-center font-semibold border-none rounded-2xl bg-[#28C152] text-white ms-2">
            High
          </div>
        </div>

        <div className="flex items-center me-4">
          <div className="me-1">Template Messaging Tier</div>
          <div
            className="tooltip tooltip-accent text-xs tooltip-bottom"
            data-tip="Your WhatsApp Business Account’s quality rating (as assessed by WhatsApp) is High, as the messages that you have been sending to your customers in the last 7 days have been of good quality.
                Know More"
          >
            <IoMdInformationCircle className="text-lg" />
          </div>
          <div className="font-semibold text-black ms-2">
            Tier 2{"   "}
            <span className="text-xs font-normal text-[#6E6E6E]">
              (10K/24 Hours)
            </span>
          </div>
        </div>

        <div className="flex items-center me-4">
          <div className="me-1">Remaining Quota</div>
          <div
            className="tooltip tooltip-accent text-xs tooltip-bottom"
            data-tip="Number of unique users you can send template messages."
          >
            <IoMdInformationCircle className="text-lg" />
          </div>
          <div className="font-medium text-lg text-black ms-2">9996</div>
        </div>

        <div className="btn btn-sm border-none bg-[#0A474C] hover:bg-[rgb(11,54,58)] text-white ms-2">
          + Luanch
        </div>
      </div>
    </div>
  );
}

function SearchBar() {
  return (
    <div className="w-5/6 mt-10 mx-auto flex justify-between items-center">
      <div>
        <label className="input input-bordered flex items-center gap-2 bg-[#F0F0F0] focus-within:bg-white w-96 focus-within:outline-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path
              fillRule="evenodd"
              d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
              clipRule="evenodd"
            />
          </svg>
          <input
            type="text"
            className="grow placeholder:text-sm placeholder:pb-1 placeholder:font-normal"
            placeholder="Search by campaign name"
          />
        </label>
      </div>
      <div className="btn btn-sm font-normal rounded-md bg-transparent text-black hover:bg-[#d8d7d7] border-none shadow-none">
        <TbReload className="text-lg" /> Refresh
      </div>
    </div>
  );
}

function Campaign({ tem_id, name, type, date, status, audience, submitted }) {
  
  // date format shorting function
  const handleFormatDate = (date) => {

    const getdate = new Date(date);

    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const newFormatedDate = getdate.toLocaleString("en-US", options);

    console.log(newFormatedDate);
    return newFormatedDate;
  };


  return (
    <Link to={`analytics/${tem_id}`}>
      <div className="my-5 w-full p-5 font-medium bg-white rounded-md grid gap-2 grid-cols-12 items-center">
        <div className="col-span-1 text-2xl">
          <BarChartIcon />
        </div>
        <div className="col-span-4">{name}</div>
        <div className="col-span-2">{type}</div>
        <div className="col-span-2">{handleFormatDate(date)}</div>
        <div className="col-span-1 text-center">
          <div className="w-14 py-0.5 text-center font-semibold border-none rounded-2xl bg-[#28C152] text-white mx-auto">
            {status}
          </div>
        </div>
        <div className="col-span-1 text-center">{audience?.length}</div>
        {/* <div className="col-span-1 text-center">{submitted?.length}</div> */}
      </div>
    </Link>
  );
}
