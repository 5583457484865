import React from "react";
import { TextComponent } from "../CreateTemplate";

function TamplateFormat({
  inputValue,
  setInputValue,
  objectsArray,
  setObjectsArray,
}) {
  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /{{(\d+)}}/g;
    let match;
    const idsFound = new Set();

    // Extract existing IDs
    while ((match = regex.exec(value)) !== null) {
      idsFound.add(parseInt(match[1], 10));
    }

    // Sort the IDs and create new sequential IDs
    const sortedIds = Array.from(idsFound).sort((a, b) => a - b);
    const idMapping = new Map();
    let currentId = 1;
    sortedIds.forEach((originalId) => {
      idMapping.set(originalId, currentId++);
    });

    // Replace the original IDs with the new sequential IDs
    let modifiedValue = value.replace(
      regex,
      (match, p1) => `{{${idMapping.get(parseInt(p1, 10))}}}`
    );

    // Create a lookup map from objectsArray
    const lookupMap = new Map(
      objectsArray.map((item) => [item.id, item.parameterVal])
    );

    // Update the new objects array with sequential IDs
    const newObjectsArray = Array.from(idMapping.values()).map((id) => ({
      id,
      parameterVal: lookupMap.get(id) || "", // Use existing value if it exists, otherwise empty string
    }));

    setInputValue(modifiedValue);
    setObjectsArray(newObjectsArray);
  };
  return (
    <div>
      <TextComponent
        heading="Template Format"
        body="Use text formatting - *bold* , _italic_ & ~strikethrough~
  Your message content. Upto 1024 characters are allowed."
        body1="e.g. - Hello {{1}}, your code will expire in {{2}} mins."
      />
      <textarea
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter your message here..."
        className="input input-bordered w-full h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
      />
    </div>
  );
}

export default TamplateFormat;
