import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";



// webhopok to get media from:sagar
const useFetchMedia = (mediatype) => {
  const [ismedia, setimage] = useState([]);
  const [isloader, setloader] = useState(false);


  const getmedia = async () => {
    try {
      if (!mediatype) {
        toast.error("please select your template");
        return;
      }
      setloader(true);
      const response = await axios.get(
        `/api/message/fetchmedia?mediatype=${mediatype}`
      );
      const data = response.data;
      setimage(data);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloader(false);
    }
  };

  useEffect(() => {
    getmedia();
  }, [mediatype]);

  const refetch = () => {
    getmedia();
  }
  // console.log("media data from backend looks likes :", ismedia);
  return { ismedia, isloader, refetch };
};

//web hook to post media  from: sagar
const usePostmedia = () => {
  const [isuploadmedia, setuploadmedia] = useState("");
  const [ismediaLoader, setmediaLoader] = useState(false);

  const uploadmedia = async (formData) => {
    if (!formData) {
      toast.error("No media uploaded.");
      return;
    }
    console.log("media in web hooks looks like ", formData);

    try {

      setmediaLoader(true);
      // const formData = new FormData();
      // formData.append('media in web hooks looks like this ', file);

      const response = await axios.post("/api/message/uploadCloud", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const data = response.data;

      setuploadmedia(data);

      // if (data.error) {
      //   toast.error(data.error); // Show error message from API
      // } else {
      //   toast.success(data.message); // Show success message
      // }
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setmediaLoader(false);
    }
  };

  console.log("response from backend looks like:", isuploadmedia);
  return { isuploadmedia, ismediaLoader, uploadmedia ,setuploadmedia };
};

//web hook  to post media from:sagar
const useDeleteMedia = () => {
  const [deletedMedia, setDeleteMedia] = useState("");
  const [isloading, setloading] = useState(false);

  const deletemedia = async ({ id }) => {
    if (!id) {
      toast.error("select media to to delete")
    }
    try {
      setloading(true)
      const response = await axios.delete(`/api/message/deletemedia/${id}`, {
        params: {
          id: id,
        },
      });



      const data = await response.data;
      console.log("repsonse from backend looks likes this while deleting media  :", data)
    
        setDeleteMedia(data);
      
    }
    catch (error) {
      console.error("error in deleting media".error.message)
    } finally {
      setloading(false)
    }
  }
  deletemedia();

  return { deletedMedia, isloading, deletemedia,setDeleteMedia }
}

export { useFetchMedia, usePostmedia, useDeleteMedia };
