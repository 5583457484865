import React from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import useMetaUserDetails from "../zustand/useMetaUserDetails";

function GlobalHeader({ item }) {
  const { metaUserDetails } = useMetaUserDetails();

  const teirValue =
    metaUserDetails?.messaging_limit_tier?.toString() || "TIER_0K";

  function getFormattedTierResponse(teirValue) {
    const match = teirValue.match(/TIER_(\d+)K/);
    if (match && match[1]) {
      const number = parseInt(match[1]) * 1000;
      return `(${number / 1000}K/${24} Hours)`;
    } else {
      return "(0/24 Hours)";
    }
  }

  const Details = {
    Status: metaUserDetails?.quality_rating || "None",
    TierValue: teirValue || "0",
    TierHours: getFormattedTierResponse(teirValue),
    RemaingCount: metaUserDetails?.remaining_quota,
  };

  return (
    <>
      <div className="flex w-full overflow-hidden justify-between items-center bg-white px-5 py-3 shadow sticky top-0 z-50">
        <div className="text-lg font-semibold">{item.Title}</div>

        <div className="flex text-sm font-normal text-[#6E6E6E] items-center">
          {item.Details && (
            <div className="flex ">
              <div className="flex items-center me-4">
                <div className="me-1">Quality Rating</div>
                <div
                  className="tooltip tooltip-accent text-xs tooltip-bottom"
                  data-tip="Your WhatsApp Business Account’s quality rating (as assessed by WhatsApp) is High, as the messages that you have been sending to your customers in the last 7 days have been of good quality.
                Know More"
                >
                  <IoMdInformationCircle className="text-lg" />
                </div>
                <div className="w-16 py-1 text-center font-semibold border-none rounded-2xl bg-[#28C152] text-white ms-2">
                  {Details.Status}
                </div>
              </div>

              <div className="flex items-center me-4">
                <div className="me-1">Template Messaging Tier</div>
                <div
                  className="tooltip tooltip-accent text-xs tooltip-bottom"
                  data-tip="Your WhatsApp Business Account’s quality rating (as assessed by WhatsApp) is High, as the messages that you have been sending to your customers in the last 7 days have been of good quality.
                Know More"
                >
                  <IoMdInformationCircle className="text-lg" />
                </div>
                <div className="font-semibold text-black ms-2">
                  {Details.TierValue}
                  {"   "}
                  <span className="text-xs font-normal text-[#6E6E6E]">
                    {Details.TierHours}
                  </span>
                </div>
              </div>

              <div className="flex items-center me-4">
                <div className="me-1">Remaining Quota</div>
                <div
                  className="tooltip tooltip-accent text-xs tooltip-bottom"
                  data-tip="Number of unique users you can send template messages."
                >
                  <IoMdInformationCircle className="text-lg" />
                </div>
                <div className="font-medium text-lg text-black ms-2">
                  {Details.RemaingCount}
                </div>
              </div>
            </div>
          )}

          {item.EndButton && (
            <Link to={item.Link}>
              <div className="btn btn-sm border-none bg-[#0A474C] hover:bg-[rgb(11,54,58)] text-white ms-2">
                {item.EndButton}
              </div>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

export default GlobalHeader;
