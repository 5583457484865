import axios from "axios";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useZusConversation from "../zustand/useZusConversation";

//create template
const useCreateTemplate = () => {
  const [istemplateresponse, setTemplateresponse] = useState(null);
  const [loadTemplate, setLoadTemplate] = useState(false);

  const navigate = useNavigate();

  const templateCreate = async ({
    templateName,
    headertext,
    languageCode,
    category,
    format,
    headerText,
    templateBody,
    templatefooter,
    body_text,
    UrlButtons,
    Phone_Number,
    QUICKREPLYtext,
  }) => {
    try {
      setLoadTemplate(true);
      const response = await axios.post("/api/message/template", {
        templateName,
        headertext,
        languageCode,
        category,
        format,
        templateBody,
        headerText,

        templatefooter,
        body_text,
        UrlButtons,
        Phone_Number,
        QUICKREPLYtext,
      });
      const data = await response.data;

      console.log("reposne data is:", data);
      console.log("reposne data is:", data.message);
      if (data.error) {
        toast.error(data.error); // Show error message from API
      } else {
        toast.success(data.message); // Show success message
        navigate("/template/message/pending"); // redirect to template page when the req res will success!
      }
    } catch (error) {
      // toast.error(error.response?.data?.error || error.message); // Show error message
      const errorMessage =
        error.response?.data?.error?.error_user_msg ||
        error.response?.data?.error ||
        error.message;
 
      toast.error(errorMessage); // Show detailed error message
    } finally {
      setLoadTemplate(false);
    }
  };

  return { loadTemplate, istemplateresponse, templateCreate };
};

// useGetTemplate.js
const useFetchTemplates = (category, status) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `/api/message/mytemplate?category=${category}&status=${status}`
        );
        setTemplates(response.data);
      } catch (error) {
        toast.error(error.response?.data?.error || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, [category, status]);

  return { templates, loading };
};

// fetch status of the template
const useFetchStatus = (status) => {
  const [isstatus, setstatus] = useState([]);
  const [load, setloader] = useState(false);

  useEffect(() => {
    const fetchStatusTemplates = async () => {
      try {
        setloader(true);
        const response = await axios.get(
          `/api/message/mytemplate?status=${status}`
        );
        setstatus(response.data);
      } catch (error) {
        toast.error(error.response?.data?.error || error.message);
      } finally {
        setloader(false);
      }
    };
    fetchStatusTemplates();
  }, [status]);
  return { isstatus, load };
};

const useDeleteTemplate = () => {
  const [isDeleted, setIsDeleted] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const deleteTemplate = async ({ id }) => {
    if (!id) {
      // Assuming templatename is not required
      return toast.error("something went wrong!");
    }
    console.log("data in web hooks:", id);
    try {
      setIsLoading(true);
      const response = await axios.delete(`/api/message/deleteTemplate/${id}`, {
        params: {
          id: id,
        },
      });
      const data = await response.data;
      console.log(data);
      toast.success("template deteted successfully");
      setIsDeleted(true); // Set isDeleted to true on successful deletion (optional)
    } catch (error) {
      toast.error(
        error.response?.data?.error ||
          "An error occurred while deleting the template"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isDeleted,
    isLoading,
    deleteTemplate,
  };
};

// this hooks will refresh data from backend and meta
const useRefreshTemplates = () => {
  const [isrefresh, setrefresh] = useState("");
  const [isloader, setloader] = useState(false);

  const refreshData = async () => {
    try {
      setloader(true);
      const resposne = await axios.get("/api/message/updatedstatus");
      const data = resposne.data;
      if (data.error) {
        toast.error(data.error); // Show error message from API
      } else {
        toast.success(data.message); // Show success message
      }
      setrefresh(data);
    } catch (error) {
      console.error("error in refreshing data looks likes this :", error);
    } finally {
      setloader(false);
    }
  };

  return { isrefresh, isloader, refreshData };
};

// this hook is used in  buck message from:sagar
const useBulkMessage = () => {
  const [isSendTemplate, setTemplate] = useState("");
  const [isloader, setloader] = useState("");

  const sendBulkMessage = async ({
    id,
    contact,
    mediaurl,
    locatioName,
    address,
    latitude,
    longitude,
    campname,
    parameters,
    conatactId,
  }) => {
    try {
      setloader(true);
      const resposne = await axios.post("/api/message/bulkmessage", {
        id,
        contact,
        mediaurl,
        campname,
        parameters,
        conatactId,
        // locatioName,
        // address,
        // latitude,
        // longitude,
      });
      const data = resposne.data;
      if (data.error) {
        toast.error(data.error); // Show error message from API
      } else {
        toast.success(data.message); // Show success message
      }

      console.log("status looks like this :", data);
    } catch (error) {
      // toast.error(error.response?.data?.error || error.message); // Show error message
      const errorMessage =
        error.response?.data?.error?.error_user_msg ||
        error.response?.data?.error ||
        error.message;
      console.log("error object looks like this :", errorMessage);
      toast.error(errorMessage); // Show detailed error message
    } finally {
      setloader(false);
    }
  };
  sendBulkMessage();

  return { isSendTemplate, isloader, sendBulkMessage };
};

//this hooks helps in single template message from:sagar bhatia
const useSingleMessage = () => {
  const [isSendTemplate, setTemplate] = useState("");
  const [isloader, setloader] = useState("");
  const { messages, setMessages, selectedConversation } = useZusConversation();

  const sendSinglemesssage = async ({
    id,
    contact,
    mediaurl,
    locatioName,
    address,
    latitude,
    longitude,
    campname,
    parameters,
    conatactId,
  }) => {
    try {
      console.log(
        " data inside hooks looks likes this : ",
        id,
        contact,
        mediaurl,
        campname,
        parameters,
        conatactId
      );
      setloader(true);
      const resposne = await axios.post(
        "/api/client/messages/sendmessageTemplate",
        {
          id,
          contact,
          mediaurl,
          campname,
          parameters,
          conatactId,

          // locatioName,
          // address,
          // latitude,
          // longitude,
        }
      );
      const data = resposne.data;

      setMessages([...messages, data]);

      console.log("status looks like this :", data);
      if (data) {
        toast.success(`message sent sucessfully..`);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.error ||
          "An error occurred while deleting the template"
      );
    } finally {
      setloader(false);
    }
  };
  sendSinglemesssage();

  return { isSendTemplate, isloader, sendSinglemesssage };
};
//this hook helps to save auto repsonse template from : sagar bhatia
const useAutoResponse = () => {
  const [isConfiguration, setConfiguration] = useState("");
  const [isloading, setloading] = useState(false);

  const newConfiguration = async ({ id, mediaurl, parameters }) => {
    try {
      setloading(true);
      const resposne = await axios.post(
        "/api/client/messages/saveconfiguration",
        {
          id,
          mediaurl,
          parameters,
        }
      );

      const data = await resposne.data;

      if (data) {
        toast.success(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error("error in creating configuration", error);
    } finally {
      setloading(false);
    }
  };
  newConfiguration();
  return { isloading, isConfiguration, newConfiguration };
};
//this hook help display userconcern template
const useShowautoResponseTemplate = () => {
  const [isData, setData] = useState(null); // Initialize with null
  const [isloader, seloader] = useState(false);

  const DisplayautoResponse = async () => {
    try {
      seloader(true);

      const response = await axios.get(
        "/api/client/messages/template/Getsavedconfigdata"
      );
      const data = response.data;

      if (data && !data.error) {
        setData(data);
      } else {
        toast.error(data?.error || "Unexpected error occurred");
      }
    } catch (error) {
      toast.error("Something went wrong...");
    } finally {
      seloader(false);
    }
  };

  useEffect(() => {
    DisplayautoResponse();
  }, []);

  const refreshData = () => {
    DisplayautoResponse();
  };

  return { isloader, isData, refreshData };
};

export {
  useCreateTemplate,
  useFetchTemplates,
  useFetchStatus,
  useDeleteTemplate,
  useBulkMessage,
  useSingleMessage,
  useAutoResponse,
  useShowautoResponseTemplate,
  useRefreshTemplates,
};
