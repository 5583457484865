import QuickGuilde from "../QuickGuilde";
import GoBackHeader from "./GoBackHeader";

export function ShopifySS() {
  const quickReplyObj = {
    text1: "Easily configure Ecomm+ to automate your shopify messages.",
    text2:
      "Automatic Abandoned Cart Reminders, Order Notifications, COD recovery on whatsapp.",
    link1: "How to get Access Token and API Secret Key",
    link2: "How to setup automated shopify messages",
  };

  const GoBackHeaderData = {
    heading: "App Store",
    link: "/ecommerce",
    logo: "https://logosandtypes.com/wp-content/uploads/2020/11/shopify.svg",
  };

  return (
    <>
      <GoBackHeader data={GoBackHeaderData} />
      <div className="">
        <QuickGuilde data={quickReplyObj} />
        <div className="w-5/6 rounded-md shadow-sm mt-10 mx-auto bg-white py-6 px-8">
          <div>
            <div className="text-[#0A474C] font-medium text-lg">Shop URL</div>
            <div className="text-[#6E6E6E] font-medium text-xs">
              Copy your Shopify store url
            </div>
            <div className="mt-2">
              <input
                type="text"
                className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
                placeholder="https://admin.shopify.com/store"
              />
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Access Token
            </div>
            <div className="text-[#6E6E6E] font-medium text-xs">
              Copy Access Token from Shopify store
            </div>
            <div className="mt-2">
              <input
                type="text"
                className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
                placeholder="Access Token"
              />
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              API Secret Key
            </div>
            <div className="text-[#6E6E6E] font-medium text-xs">
              Copy API Secret key from Shopify store
            </div>
            <div className="mt-2">
              <input
                type="text"
                className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
                placeholder="Api Secret Key"
              />
            </div>
          </div>
          <div className="mt-5 btn w-24 bg-[#0A474C] text-white border-none rounded-md btn-sm">
            Connect
          </div>
        </div>

        <div className="w-5/6 rounded-md shadow-sm mt-10 mx-auto mb-10 bg-white py-6 px-8">
          <div>
            <div className="text-[#0A474C] font-medium text-lg">
              About Shopify
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Shopify is an eCommerce platform, enabling businesses to create a
              customized gateway to their business, including website, payment,
              inventory, and shipping management tools.
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              KAKAA - Shopify
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Connecting Shopify with THEBOTMODE will help you send the
              following automated notifications to your customers WhatsApp. Some
              simple automation you can send:
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              <ul className="ulStyle ms-5">
                <li>Abandoned Cart Recovery Notifications</li>
                <li>
                  Payment Updates like Payment Failure, Payment Pending or
                  Payment completed
                </li>
                <li>
                  Order Confirmation notifications like Order Completed or order
                  cancelled
                </li>
                <li>Customers order id with delivery updates</li>
              </ul>
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Alongside you can solve customer support queries via Live Chat or
              Chatbots on Whatsapp for your E-Commerce Store.
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Boost Your Sales
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Abandoned Cart Recovery notifications recover 45-60% of Abandoned
              Carts in No Time! Boosting your sales instantly.
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Broadcast Notifications at Scale
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Broadcast Notifications to your customers and users about product
              updates, feedback and more
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Solve for Customer Support
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Get Multiple Human Live Chat Support on Whatsapp and Solve for
              Customer Support on Whatsapp.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function WooCommerceSS() {
  const quickReplyObj = {
    text1: "EEasily configure Ecomm+ to automate your woocommerce messages.",
    text2:
      "Automatic Abandoned Cart Reminders, Order Notifications, COD recovery on whatsapp.",
    link1: "How to get Access Token and API Secret Key",
    link2: "How to setup automated woocommerce messages",
  };

  const GoBackHeaderData = {
    heading: "App Store",
    link: "/ecommerce",
    logo: "/assets/woocommerce_icon.png",
  };

  return (
    <>
      <GoBackHeader data={GoBackHeaderData} />
      <div className="">
        <QuickGuilde data={quickReplyObj} />
        <div className="w-5/6 rounded-md shadow-sm mt-10 mx-auto bg-white py-6 px-8">
          <div>
            <div className="text-[#0A474C] font-medium text-lg">Shop Name</div>
            <div className="text-[#6E6E6E] font-medium text-xs">
              Enter your Woocommerce store name
            </div>
            <div className="mt-2">
              <input
                type="text"
                className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
                placeholder="Shop Name"
              />
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">Shop URL</div>
            <div className="text-[#6E6E6E] font-medium text-xs">
              Enter shop url
            </div>
            <div className="mt-2">
              <input
                type="text"
                className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
                placeholder="/assets/woocommerce_icon.png"
              />
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Consumer Key
            </div>
            <div className="text-[#6E6E6E] font-medium text-xs">
              Copy Consumer Key from your Woocommerce store
            </div>
            <div className="mt-2">
              <input
                type="text"
                className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
                placeholder="Consumer Key"
              />
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Consumer Secret
            </div>
            <div className="text-[#6E6E6E] font-medium text-xs">
              Copy Consumer Secret from your Woocommerce store
            </div>
            <div className="mt-2">
              <input
                type="text"
                className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
                placeholder="Consumer Secret"
              />
            </div>
          </div>
          <div className="mt-5 btn w-24 bg-[#0A474C] text-white border-none rounded-md btn-sm">
            Connect
          </div>
        </div>

        <div className="w-5/6 rounded-md shadow-sm mt-10 mx-auto mb-10 bg-white py-6 px-8">
          <div>
            <div className="text-[#0A474C] font-medium text-lg">
              About WooCommerce
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              WooCommerce is a free WordPress plugin that adds e-commerce
              functionality to your WordPress website so you can have an online
              store. It helps you turn your WordPress website into an E-Commerce
              store instantly.
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              THEBOTMODE - WooCommerce
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Connecting WooCommerce with KoreGrow will help you send the
              following automated notifications to your customers WhatsApp. Some
              simple automation you can send:
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              <ul className="ulStyle ms-5">
                <li>Abandoned Cart Recovery Notifications</li>
                <li>
                  Payment Updates like Payment Failure, Payment Pending or
                  Payment completed
                </li>
                <li>
                  Order Confirmation notifications like Order Completed or order
                  cancelled
                </li>
                <li>Customers order id with delivery updates</li>
              </ul>
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Alongside you can solve customer support queries via Live Chat or
              Chatbots on Whatsapp for your E-Commerce Store.
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Boost Your Sales
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Abandoned Cart Recovery notifications recover 45-60% of Abandoned
              Carts in No Time! Boosting your sales instantly.
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Broadcast Notifications at Scale
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Broadcast Notifications to your customers and users about product
              updates, feedback and more
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Solve for Customer Support
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Get Multiple Human Live Chat Support on Whatsapp and Solve for
              Customer Support on Whatsapp.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
