import React from "react";
import { MdMenuBook } from "react-icons/md";

function QuickGuilde({ data }) {
  return (
    <div>
      <div
        className={`mt-10 ${
          data.width ? "w-11/12" : "w-5/6"
        } rounded-md shadow-sm py-6 px-8  mx-auto bg-white`}
      >
        <div className="text-lg font-medium mb-2">Quick Guide</div>
        <div className="text-[#6E6E6E] font-medium">{data.text1}</div>
        {data.text2 && (
          <div className="text-[#6E6E6E] font-medium">{data.text2}</div>
        )}

        <div className="flex text-[#0A474C] mt-3 w-full">
          <p className="w-1/2 flex font-medium text-sm hover:underline my-2">
            <MdMenuBook className="text-lg me-2" />
            <a href="#">{data.link1}</a>
          </p>
          {data.link2 && (
            <p className="w-1/2 flex font-medium text-sm hover:underline my-2">
              <MdMenuBook className="text-lg me-2" />
              <a href="#">{data.link2}</a>
            </p>
          )}
        </div>
        {data.MoreLink && (
          <div className="flex text-[#0A474C] mt-3 w-full">
            <p className="w-1/2 flex font-medium text-sm hover:underline my-2">
              <MdMenuBook className="text-lg me-2" />
              <a href="#">{data.MoreLink.link3}</a>
            </p>
            <p className="w-1/2 flex font-medium text-sm hover:underline my-2">
              <MdMenuBook className="text-lg me-2" />
              <a href="#">{data.MoreLink.link4}</a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default QuickGuilde;
